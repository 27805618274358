import React, { useRef } from "react";
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  ButtonProps,
} from "@mui/material";
import { useBalance } from "wagmi";
import wtfAvatar from "../../data/wtf_circle.png";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

const JoinButton = styled(Button)<ButtonProps>(({ theme }) => ({
  position: "relative",
  fontSize: "1.1rem",
  padding: "5px 20px",
  borderRadius: "3px",
  overflow: "hidden",
  background:
    "linear-gradient(to right, #000000 0%, #000000 37%, #000000 100%)!important",
  backgroundSize: "600% 600%",
  animation: "$rainbowAnimation 8s infinite linear",
  color: "#f7dd85",
  fontWeight: "bold",
  border: "none",
  "&:hover": {
    boxShadow: "2px 2px",
    background: "black",
    color: "white",
  },
  width: "150px",
  fontFamily: "MedievalSharp",
}));

export const Balance = ({ user }: any) => {
  const { data: ethBalance } = useBalance({
    address: user.address,
  });

  const { data: boringBalance } = useBalance({
    address: user.address,
    token: "0xF3cE370178244eB2A5573746cA0dDeCcbfC3aDA3",
  });

  const windowSize = useRef<any[]>([window.innerWidth, window.innerHeight]);
  const handleUserIdShow = () => {
    if (windowSize.current[0] < 640) {
      // mobile
      return (
        user.address.slice(0, 7) +
        "..." +
        user.address.substr(user.address.length - 7)
      );
    } else if (windowSize.current[0] < 1024) {
      // tablet
      return (
        user.address.slice(0, 10) +
        "..." +
        user.address.substr(user.address.length - 10)
      );
    } else if (windowSize.current[0] < 1440) {
      // laptop
      return user.address;
    } else if (windowSize.current[0] < 1800) {
      // desktop
      return user.address;
    } else {
      // large desktop
      return user.address;
    }
  };

  if (user.address === "0x0000000000000000000000000000000000000000") {
    return (
      <Paper sx={{ marginBottom: "50px" }}>
        <Box sx={{ padding: "15px", boxSizing: "border-box", gridGap: "15px" }}>
          <Typography
            sx={{
              fontFamily: "MedievalSharp",
              fontSize: { mobile: "1.2rem", laptop: "1.2rem" },
              marginBottom: { laptop: "5px", desktop: "20px" },
            }}
            gutterBottom
          >
            <b>Account Information</b>
          </Typography>
          <Stack spacing={2}>
            <Typography
              sx={{
                fontSize: { mobile: "0.8rem", laptop: "0.8rem" },
                marginRight: "10px",
                color: "#A5A5A5",
              }}
              gutterBottom
            >
              It looks you never interacted with our app!
            </Typography>
            <Link
              to="/feed"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              <Button variant="contained">Launch App</Button>
            </Link>
          </Stack>
        </Box>
      </Paper>
    );
  }

  return (
    <React.Fragment>
      <Paper
        sx={{
          marginBottom: { mobile: "5px", laptop: "50px" },
          width: { mobile: "100%", laptop: "70%" },
        }}
      >
        <Box
          sx={{
            padding: "15px",
            boxSizing: "border-box",
            gridGap: "15px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              fontFamily: "MedievalSharp",
              fontSize: { mobile: "1.2rem", laptop: "1.2rem" },
              marginBottom: { laptop: "5px", desktop: "20px" },
            }}
            gutterBottom
          >
            <b>Account Information</b>
          </Typography>
          <Stack spacing={1}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: { mobile: "0.8rem", laptop: "0.8rem" },
                  marginRight: "10px",
                  color: "#A5A5A5",
                }}
                gutterBottom
              >
                P. Key
              </Typography>
              <Typography
                sx={{
                  fontSize: { mobile: "0.7rem", laptop: "1rem" },
                  marginRight: "10px",
                }}
                gutterBottom
              >
                {user.address}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                sx={{
                  fontSize: { mobile: "0.8rem", laptop: "0.8rem" },
                  marginRight: "10px",
                  color: "#A5A5A5",
                }}
                gutterBottom
              >
                Join Date
              </Typography>
              <Typography
                sx={{
                  fontSize: { mobile: "0.9rem", laptop: "1rem" },
                  marginRight: "10px",
                }}
                gutterBottom
              >
                {new Date(user.created_at).toLocaleString("en", {
                  month: "long",
                }) +
                  " " +
                  new Date(user.created_at).getUTCFullYear()}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Typography
                sx={{
                  fontSize: { mobile: "0.8rem", laptop: "0.8rem" },
                  marginRight: "10px",
                  color: "#A5A5A5",
                }}
                gutterBottom
              >
                ETH Balance
              </Typography>
              <Typography
                sx={{ fontSize: { mobile: "0.9rem", laptop: "1rem" } }}
                gutterBottom
              >
                {ethBalance
                  ? Number(ethBalance?.formatted).toLocaleString("en-us", {
                      minimumFractionDigits: 4,
                    })
                  : "0.0000"}
              </Typography>
              <img
                alt="eth logo"
                src={
                  "https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628"
                }
                width="20px"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Typography
                sx={{
                  fontSize: { mobile: "0.8rem", laptop: "0.8rem" },
                  marginRight: "10px",
                  color: "#A5A5A5",
                }}
                gutterBottom
              >
                BORING Balance
              </Typography>
              <Typography
                sx={{ fontSize: { mobile: "0.9rem", laptop: "1rem" } }}
                gutterBottom
              >
                {boringBalance
                  ? Number(boringBalance?.formatted).toLocaleString("en-us", {
                      minimumFractionDigits: 4,
                    })
                  : "0.0000"}
              </Typography>
              <img alt="avatar" src={wtfAvatar} width="20px" />
              <Button variant="outlined" size="small" disabled>
                BUY
              </Button>
            </Box>
          </Stack>
        </Box>
      </Paper>
    </React.Fragment>
  );
};
