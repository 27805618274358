import React, { useState, useEffect } from "react";
import { Alert, Box, Snackbar } from "@mui/material";

export const ErrorHandler = ({ returnedError }: any) => {
  const [showErrorSnack, setShowErrorSnack] = useState<boolean>(true);
  const [errorID, setErrorID] = useState<number>(returnedError);
  const [errorText, setErrorText] = useState<string>("");

  const createErrorText = (returnedError: number) => {
    if (returnedError === 429) {
      setErrorText("Rate limit reached. Please try again later.");
    } else if ((returnedError = 10)) {
      setErrorText("API Error. Please try again later.");
    } else {
      setErrorText("Unknown error. Please try again later.");
    }
  };

  useEffect(() => {
    createErrorText(errorID);
  }, [errorID]);

  const handleCloseSnack = () => {
    setShowErrorSnack(false);
  };

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Snackbar
          open={showErrorSnack}
          autoHideDuration={10000}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          onClose={handleCloseSnack}
        >
          <Alert onClose={handleCloseSnack} severity="error">
            {errorID}: {errorText}
          </Alert>
        </Snackbar>
      </Box>
    </React.Fragment>
  );
};
