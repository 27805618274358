import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import { Box, Typography, Card, CardContent, CardHeader } from "@mui/material";
import landscape from "../../data/landscape.jpg";

export const Roadmap = () => {
  const roadmap = [
    {
      deadline: "June 1568",
      phase: "The corporate life",
      content:
        "Not Pieter Brueghel starts his first (and last) corporate job, that he will quit a few months later.",
    },
    {
      deadline: "March 1569",
      phase: "The resignation",
      content:
        "Not Pieter Brueghel starts militating against what he calls the 'corporate prison'.",
    },
    {
      deadline: "...",
      phase: "Time gap",
      content: <i>(this is just to make the story short)</i>,
    },
    {
      deadline: "July 2024",
      phase: "The start",
      content:
        "We continue the legacy of Not Pieter Brueghel by offering the opportunity to all corporate workers to get out of the corporate life.",
    },
    {
      deadline: "H2 2024",
      phase: "The rise",
      content:
        "More and more people adhere to Not Pieter Brueghel vision and try to escape the corporate life.",
    },
    {
      deadline: "2025 onwards",
      phase: "The solution",
      content:
        "Deserving peasants are beginning to reap the fruits of their efforts.",
    },
  ];

  return (
    <React.Fragment>
      <Box
        sx={{
          flexDirection: { mobile: "column", tablet: "row-reverse" },
          display: "flex",
          border: "0px solid yellow",
          pointerEvent: "auto",
        }}
      >
        <Card
          variant="outlined"
          sx={{
            flexDirection: {
              mobile: "column",
              tablet: "column",
              laptop: "column",
            },
            display: "flex",
            alignItems: "center",
            gridGap: "25px",
            padding: "15px",
            boxSizing: "border-box",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                mobile: "column",
                tablet: "row",
                laptop: "column",
              },
              width: { mobile: "100%", laptop: "100%" },
            }}
          >
            <Box
              sx={{
                flexDirection: { mobile: "row", tablet: "row" },
                width: { mobile: "100%", laptop: "100%" },
                marginBottom: "10px",
              }}
            >
              <CardHeader
                subheader={
                  <Typography
                    sx={{ fontFamily: "Monospace", color: "text.secondary" }}
                  >
                    <b>From Not Pieter Brueghel start in 1569 to our days</b>
                  </Typography>
                }
                title={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { mobile: "column", tablet: "row" },
                      alignItems: "left",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "MedievalSharp",
                        fontSize: {
                          mobile: "1.2rem",
                          tablet: "1.3rem",
                          desktop: "2rem",
                        },
                        marginRight: "10px",
                      }}
                      gutterBottom
                    >
                      <b>The liberty road</b>
                    </Typography>
                  </Box>
                }
              />
              <CardContent>
                <Timeline
                  sx={{
                    width: "100%",
                    flexDirection: { mobile: "column", tablet: "column" },
                    display: "flex",
                    alignItems: "left",
                  }}
                >
                  {roadmap.map((item: any, index: any) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          marginBottom: "20px",
                        }}
                      >
                        <Box sx={{ width: "15%", marginRight: "20px" }}>
                          <Box>
                            <Typography
                              sx={{
                                fontSize: { mobile: "0.8rem", laptop: "1rem" },
                              }}
                            >
                              {item.deadline}
                            </Typography>
                          </Box>
                        </Box>
                        <Box sx={{ width: "85%" }}>
                          <Box>
                            <Box>
                              <Typography sx={{ fontFamily: "MedievalSharp" }}>
                                {item.phase}
                              </Typography>
                            </Box>
                            <Box>{item.content}</Box>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })}
                </Timeline>
              </CardContent>
            </Box>
          </Box>
          <Box sx={{ width: { mobile: "100%", laptop: "75%" } }}>
            <img
              src={landscape}
              width="100%"
              style={{ borderRadius: "10px" }}
            />
            <Typography
              sx={{
                fontSize: {
                  mobile: "0.7rem",
                  laptop: "0.75rem",
                  desktop: "0.8rem",
                },
                textAlign: "center",
              }}
              gutterBottom
            >
              <i>The Way Out</i>, Not Pieter Brueghel's preferred landscape
            </Typography>
          </Box>
        </Card>
      </Box>
    </React.Fragment>
  );
};
