import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ChatIcon from "@mui/icons-material/Chat";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import { Outlet, Link } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Feed } from "./feed/Feed";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { type Container, type ISourceOptions } from "@tsparticles/engine";
import { loadSlim } from "@tsparticles/slim";
import { useEffect, useState, useMemo } from "react";
import wtfAvatar from "../data/wtf_circle.png";
import { User } from "./user/User";
import { Post } from "./post/Post";
import { Wallet } from "./wallet/Wallet";
import { Error404 } from "./tools/Error404";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import { useGlobalThemeContext } from "./home/ThemeContext";
import { darkTheme, lightTheme } from "./customFormat";
import ArticleIcon from "@mui/icons-material/Article";
import { Home } from "./home/Home";
import { useGlobalUserContext } from "../crypto/UserContext";
import { Connector } from "../crypto/CustomConnector";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import { Loading } from "./tools/Loading";

const drawerWidth = 150;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("mobile")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const Header = () => {
  const [open, setOpen] = useState(false);
  const { theme, setTheme } = useGlobalThemeContext();
  const { user, setUser } = useGlobalUserContext();
  console.log("current theme", theme);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [init, setInit] = useState(false);

  // this should be run only once per application lifetime
  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const particlesLoaded = (container?: Container) => {
    console.log(container);
  };

  const options: ISourceOptions = useMemo(
    () => ({
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: false,
            mode: "push",
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
        },
      },
      particles: {
        color: {
          value: "#ffffff",
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "out",
          },
          random: false,
          speed: 0.5,
          straight: false,
        },
        rotate: {
          path: true,
        },
        number: {
          density: {
            enable: true,
          },
          value: 100,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: theme === darkTheme ? ["image", "circle"] : ["circle"],
          options: {
            image: {
              height: theme === darkTheme ? 100 : 0,
              src: wtfAvatar,
              width: theme === darkTheme ? 100 : 0,
            },
            stroke: {
              width: 0,
              color: theme === darkTheme ? "#000000" : "black",
            },
            polygon: {
              nb_sides: 5,
            },
          },
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
      detectRetina: true,
    }),
    []
  );

  const windowSize = React.useRef<any[]>([
    window.innerWidth,
    window.innerHeight,
  ]);

  const appBarFormat = () => {
    if (windowSize.current[0] < 1440) {
      return (
        <AppBar
          position="absolute"
          style={{ background: "transparent", boxShadow: "none" }}
        >
          <Box
            sx={{
              marginLeft: "auto",
              marginRight: "20px",
              padding: "15px",
              boxSizing: "border-box",
            }}
          >
            <Connector />
          </Box>
        </AppBar>
      );
    } else {
      return (
        <AppBar
          position="absolute"
          style={{ background: "transparent", boxShadow: "none" }}
          sx={{ pointerEvent: "auto", width: "100%" }}
        >
          <Box
            sx={{
              marginLeft: "auto",
              marginRight: "20px",
              padding: "15px",
              boxSizing: "border-box",
              zIndex: 50,
            }}
          >
            <Connector />
          </Box>
        </AppBar>
      );
    }
  };

  if (init) {
    return (
      <BrowserRouter>
        <Particles id="tsparticlesHeader" options={options} />

        {appBarFormat()}

        <Drawer variant="permanent" open={open}>
          <Box
            sx={{ display: "flex", justifyContent: "center", zIndex: 99999999 }}
          >
            <DrawerHeader>
              <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
                <img src={wtfAvatar} width="30px" />
              </Link>
            </DrawerHeader>
          </Box>
          <Divider />
          <List>
            <ListItem key={"Info"} disablePadding sx={{ display: "block" }}>
              <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <AgricultureIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Info"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>

            <ListItem key={"Feed"} disablePadding sx={{ display: "block" }}>
              <Link
                to="/feed"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ChatIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Feed"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
          </List>
          <Divider />

          {user.isConnected ? (
            <List>
              <ListItem key={"Wallet"} disablePadding sx={{ display: "block" }}>
                <Link
                  to="/wallet"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Wallet"}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
            </List>
          ) : (
            <></>
          )}

          <List sx={{ position: "absolute", bottom: 0 }}>
            <ListItem key={"Docs"} disablePadding sx={{ display: "block" }}>
              <Link
                to="https://docs.boringday.xyz/"
                style={{ color: "inherit", textDecoration: "none" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <ArticleIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Brightness"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            </ListItem>
            <ListItem key={"Wallet"} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Brightness4Icon
                    onClick={() =>
                      setTheme(theme === darkTheme ? lightTheme : darkTheme)
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary={"Brightness"}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>
        <Outlet />

        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
            <Route path="/feed/" element={<Feed />} />
            <Route path="/wallet/" element={<Wallet />} />
            <Route path="/user/:id" element={<User />} />
            <Route path="/post/:id" element={<Post />} />
            <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    );
  }

  return <Loading />;
};
