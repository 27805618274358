import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { darkTheme, sxCommonFeed, sxCommonFeedLight } from "../customFormat";
import {
  Button,
  Card,
  Chip,
  Snackbar,
  Typography,
  Fab,
  Divider,
  IconButton,
} from "@mui/material";
import { loadingPost } from "../../data/posts";
import { FeedPost } from "./FeedPost";
import { Footer } from "../home/Footer";
import CircularProgress from "@mui/material/CircularProgress";
import { Help } from "./HelpModal";
import Alert from "@mui/material/Alert";
import ChatIcon from "@mui/icons-material/Chat";
import DiamondIcon from "@mui/icons-material/Diamond";
import { useGlobalThemeContext } from "../home/ThemeContext";
import ForumSharpIcon from "@mui/icons-material/ForumSharp";
import SettingsIcon from "@mui/icons-material/Settings";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Error404 } from "../tools/Error404";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { useGlobalUserContext } from "../../crypto/UserContext";
import { ErrorHandler } from "../tools/APIStatusHandler";
import { Explain } from "./ExplainModal";
import ReactConfetti from "react-confetti";
import { APIUrl } from "../tools/APISource";

let maxPostSize = 3500;

interface Post {
  id: number;
  address: string;
  categories: string[];
  content: string;
  dislikes_count: number;
  likes_count: number;
  reports_count: number;
  score: number;
}

export const Feed = () => {
  const [posts, setPosts] = useState<Post[]>(loadingPost);
  const [error, setError] = useState<boolean>(false);
  const [statusID, setStatusID] = useState<number>(200);
  const [loading, setLoading] = useState<boolean>(false);
  const [pLimit, setPLimit] = useState<number>(10);
  const [pSkip, setPSkip] = useState<number>(0);
  const [pInitialLimit, setPInitialLimit] = useState<number>(10);
  const [pInitialSkip, setPInitialSkip] = useState<number>(0);
  const [feedFilterCategory, setFeedFilterCategory] = useState<string>("all");
  const { user, setUser } = useGlobalUserContext();
  const { theme, setTheme } = useGlobalThemeContext();
  const [fetchEnd, setFetchEnd] = useState<Boolean>(false);
  const valueRef = useRef<HTMLInputElement>(null);
  const [alertCategories, setAlertCategories] = useState<Boolean>(false);
  const [alertContent, setAlertContent] = useState<Boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showToken, setShowToken] = useState<boolean>(false);
  const [showWrite, setShowWrite] = useState<boolean>(false);
  const [showCardSettings, setShowCardSettings] = useState<boolean>(true);
  const [showCardToken, setShowCardToken] = useState<boolean>(true);
  const [showCardWrite, setShowCardWrite] = useState<boolean>(true);
  const [showPostError, setShowPostError] = useState<boolean>(false);
  const [showPostErrorDesc, setShowPostErrorDesc] = useState<any>("");
  const [showConfetti, setShowConfetti] = useState<boolean>(false);

  const getPosts = async (
    limit: any,
    skip: any,
    category: string,
    isAsc: Boolean
  ) => {
    try {
      const response = await axios.get(`${APIUrl}/posts`, {
        params: {
          limit: limit,
          skip: skip,
          category: category,
          is_asc: isAsc,
          address: user.address
            ? user.address
            : "0x0000000000000000000000000000000000000000",
        },
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      console.log("response data get post", response);
      return response;
    } catch (error: any) {
      console.log(error.response);
      return { status: 10, data: {} };
    }
  };

  const initialFetch = async () => {
    console.log("fetch");
    const response = await getPosts(
      pInitialLimit,
      pInitialSkip,
      feedFilterCategory,
      true
    );
    setPSkip(pLimit);
    setStatusID(response.status);
    if (response.status === 200) {
      setFetchEnd(false);
      setLoading(false);
      return setPosts(response.data);
    } else {
      setLoading(false);
      setFetchEnd(true);
      return;
    }
  };

  const fetchMore = async () => {
    console.log("fetching more!");
    if (!fetchEnd) {
      const response = await getPosts(pLimit, pSkip, feedFilterCategory, true);
      setStatusID(response.status);
      if (response.status === 200) {
        setPSkip(pSkip + pLimit);
        if (response.data.length < pLimit) {
          setFetchEnd(true);
        }
        setLoading(false);
        return setPosts((posts) => [...posts, ...response.data]);
      } else {
        setLoading(false);
        setFetchEnd(true);
        return;
      }
    }
    setLoading(false);
  };
  console.log("current posts", posts);

  const postPost = async (address: any, content: any, category: any) => {
    try {
      const response = await axios.request({
        url: `${APIUrl}/add-post`,
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ address, content, category }),
        withCredentials: true,
      });
      if (response.status === 200) {
        setStatusID(response.status);
        const newPost = {
          id: response.data.message._post_id,
          address: user.address,
          categories: ["all", category],
          comments: null,
          content: content,
          created_at: Date.now(),
          dislikes_count: 0,
          has_disliked: false,
          has_liked: false,
          has_reported: false,
          likes_count: 0,
          reports_count: 0,
          score: response.data.message._user_score,
        };
        setShowPostSuccess(true);
        setShowConfetti(true);
        setPosts([newPost, ...posts]);
        return true;
      } else {
        setStatusID(response.status);
        return false;
      }
    } catch (error: any) {
      setStatusID(error.response.status);
      return false;
    }
  };

  useEffect(() => {
    console.log("Initial data load");
    //initialFetch()
    console.log("loaded");
  }, []);

  const feedCategories = [
    "General Bore",
    "WTF",
    "Non Human-Ressources",
    "Whoops",
    "F*cking Management",
    "Team Trouble",
    "Red flag",
    "Testimonials",
    "Toxic Culture",
  ];
  const [checkedCategories, setCheckedCategories] = useState<boolean[]>(
    new Array(feedCategories.length).fill(false)
  );

  const [postCategories, setPostCategories] = useState<string[]>([]);
  const [forceUpdate, setForceUpdate] = useState(false);

  const handleClick = (event: any) => {
    const {
      target: { textContent },
    } = event;
    const newPostCategories = [];
    newPostCategories.push(textContent);
    setPostCategories(newPostCategories);
    setForceUpdate((prev) => !prev);
  };

  const handleFeedFilter = (event: any) => {
    const {
      target: { textContent },
    } = event;
    const newCheckedCategories = new Array(feedCategories.length).fill(false);
    if (feedFilterCategory === textContent) {
      setFeedFilterCategory("all");
    } else {
      const catIndex = feedCategories.indexOf(textContent);
      newCheckedCategories[catIndex] = true;
      setFeedFilterCategory(textContent);
    }
    setCheckedCategories(newCheckedCategories);
  };

  useEffect(() => {
    console.log("refetching with", feedFilterCategory);
    initialFetch();
  }, [feedFilterCategory]);

  const handleNewPost = async () => {
    if (valueRef.current?.value.length === 0) {
      setAlertContent(true);
      setAlertCategories(false);
      return;
    }
    if (postCategories.length === 0) {
      setAlertCategories(true);
      setAlertContent(false);
      return;
    }
    if (user.isConnected) {
      setAlertCategories(false);
      const isSuccess = await postPost(
        user.address,
        valueRef.current?.value,
        postCategories[0]
      );
      if (isSuccess) {
        console.log("New post created!");
        setPostCategories([]);
      }
      if (valueRef.current !== null) {
        valueRef.current.value = "Feel like a modern peasant? Say it!";
      }
    } else {
      console.log("You should connect first");
    }
  };

  const ref = useRef();
  const [showPostSuccess, setShowPostSuccess] = useState(false);

  const handleCloseShowPostSuccessSnack = () => {
    setShowPostSuccess(false);
  };

  const handleCloseShowPostErrorSnack = () => {
    setShowPostError(false);
  };

  useEffect(() => {
    if (window.innerWidth < 640) {
      setShowSettings(false);
      setShowToken(false);
      setShowWrite(false);
      setShowCardSettings(false);
      setShowCardToken(false);
      setShowCardWrite(false);
    } else {
      setShowSettings(true);
      setShowToken(true);
      setShowWrite(true);
    }
  }, []);

  const handleShowSettings = () => {
    setShowSettings(!showSettings);
    setShowCardSettings(!showCardSettings);
    setShowToken(false);
    setShowCardToken(false);
    setShowWrite(false);
    setShowCardWrite(false);
  };

  const handleShowToken = () => {
    setShowSettings(false);
    setShowCardSettings(false);
    setShowToken(!showToken);
    setShowCardToken(!showCardToken);
    setShowWrite(false);
    setShowCardWrite(false);
  };

  const handleShowWrite = () => {
    if (window.innerWidth < 640) {
      setShowSettings(false);
      setShowCardSettings(false);
      setShowToken(false);
      setShowCardToken(false);
      setShowWrite(!showWrite);
      setShowCardWrite(!showCardWrite);
    }
  };

  const handleClickPostFAB = () => {
    if (window.innerWidth < 640) {
      setShowSettings(false);
      setShowCardSettings(false);
      setShowToken(false);
      setShowCardToken(false);
      setShowWrite(true);
      setShowCardWrite(true);
    } else {
      setShowWrite(true);
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (error) {
    return <Error404 />;
  }

  return (
    <Box>
      {showConfetti ? (
        <ReactConfetti
          width={0.95}
          recycle={false}
          gravity={0.05}
          numberOfPieces={200}
        />
      ) : (
        <></>
      )}
      <Explain />
      <Box
        sx={{
          display: "flex",
          flexDirection: { mobile: "column", laptop: "row", desktop: "row" },
          border: "0x solid red",
          justifyContent: "center",
          marginLeft: { mobile: "2%", laptop: "1%", desktop: "10%" },
          marginRight: { mobile: "2%", laptop: "1%", desktop: "15%" },
          marginBottom: "100px",
        }}
      >
        <Box
          sx={{
            border: "0px solid green",
            width: { mobile: "100%", laptop: "20%", desktop: "20%" },
            height: "100%",
            position: { mobile: "relative", laptop: "sticky" },
            top: { mobile: 1, laptop: 20, desktop: 70 },
            left: { mobile: 0, laptop: 10, desktop: 100 },
            marginTop: { mobile: "20%", laptop: "6%", desktop: "5%" },
          }}
        >
          <Box
            sx={{
              display: { mobile: "flex", laptop: "none" },
              gridGap: "15px",
              marginBottom: "15px",
              justifyContent: "center",
            }}
          >
            <Button
              component="label"
              color={showSettings ? "primary" : "secondary"}
              variant="outlined"
              tabIndex={-1}
              startIcon={<SettingsIcon fontSize="small" />}
              onClick={() => handleShowSettings()}
            >
              Settings
            </Button>
            <Button
              component="label"
              color={showToken ? "primary" : "secondary"}
              variant="outlined"
              tabIndex={-1}
              startIcon={<CurrencyBitcoinIcon fontSize="small" />}
              onClick={() => handleShowToken()}
            >
              Earn
            </Button>
            <Button
              component="label"
              color={showWrite ? "primary" : "secondary"}
              variant="outlined"
              tabIndex={-1}
              startIcon={<ForumSharpIcon fontSize="small" />}
              onClick={() => handleShowWrite()}
            >
              Post
            </Button>
          </Box>

          {showCardSettings ? (
            <Card
              variant={showSettings ? undefined : undefined}
              sx={{
                boxShadow: {
                  mobile: `${theme.palette.primary.main}4D 0px 5px, ${theme.palette.primary.main}33 0px 10px, ${theme.palette.primary.main}1D 0px 15px, ${theme.palette.primary.main}0D 0px 20px`,
                  laptop: "inherit",
                },
                marginBottom: {
                  mobile: "20px",
                  laptop: "10px",
                  desktop: showSettings ? "50px" : "10px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: { mobile: "10px", laptop: "15px" },
                  boxSizing: "border-box",
                }}
              >
                <Box
                  onClick={() => {
                    if (window.innerWidth < 640) {
                      setShowSettings(!showSettings);
                    }
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "MedievalSharp",
                      fontSize: { mobile: "1.1rem", laptop: "1.2rem" },
                      color: showSettings ? "inherit" : "#868686",
                      marginBottom: {
                        mobile: "0px",
                        laptop: "0px",
                        desktop: "0px",
                      },
                    }}
                  >
                    <b>Settings</b>
                  </Typography>
                  {showSettings ? (
                    <IconButton>
                      <KeyboardArrowUpIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton>
                      <SettingsIcon
                        fontSize="small"
                        sx={{ color: showSettings ? "inherit" : "#868686" }}
                      />
                    </IconButton>
                  )}
                </Box>
                {showSettings ? (
                  <Box
                    sx={{
                      marginBottom: {
                        mobile: "5px",
                        laptop: "5px",
                        desktop: "10px",
                      },
                      marginTop: {
                        mobile: "5px",
                        laptop: "5px",
                        desktop: "20px",
                      },
                    }}
                  >
                    <Typography
                      sx={{ fontSize: { mobile: "1rem", laptop: "0.8rem" } }}
                      gutterBottom
                    >
                      <i>Category filters</i>
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: 0.5,
                        marginBottom: {
                          mobile: "5px",
                          laptop: "5px",
                          desktop: "20px",
                        },
                      }}
                    >
                      {feedCategories.map((cat: string, index: any) => {
                        return (
                          <Chip
                            key={index}
                            size="small"
                            label={cat}
                            sx={{
                              fontSize: {
                                mobile: "0.7rem",
                                laptop: "0.7rem",
                                desktop: "0.8rem",
                              },
                            }}
                            color={
                              checkedCategories[index] ? "primary" : "default"
                            }
                            onClick={handleFeedFilter}
                          />
                        );
                      })}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { laptop: "row", desktop: "row" },
                        flexWrap: "wrap",
                        gap: { mobile: 1, laptop: 1, desktop: 0.5 },
                        alignItems: "center",
                      }}
                    >
                      <Help />
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Card>
          ) : (
            <></>
          )}

          {showCardToken ? (
            <Card
              variant={showToken ? undefined : undefined}
              sx={{
                boxShadow: {
                  mobile: `${theme.palette.primary.main}4D 0px 5px, ${theme.palette.primary.main}33 0px 10px, 
                  ${theme.palette.primary.main}1D 0px 15px, ${theme.palette.primary.main}0D 0px 20px`,
                  laptop: "inherit",
                },
                marginBottom: {
                  mobile: "20px",
                  laptop: "10px",
                  desktop: showSettings ? "50px" : "10px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: { mobile: "10px", laptop: "15px" },
                  boxSizing: "border-box",
                }}
              >
                <Box
                  onClick={() => {
                    if (window.innerWidth < 640) {
                      setShowToken(!showToken);
                    }
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "MedievalSharp",
                      fontSize: {
                        mobile: "1.1rem",
                        laptop: "1.2rem",
                        desktop: "1.2rem",
                      },
                      color: showToken ? "inherit" : "#868686",
                      marginBottom: {
                        mobile: "0px",
                        laptop: "0px",
                        desktop: "0px",
                      },
                    }}
                  >
                    <b>Stake and Earn</b>
                  </Typography>
                  {showToken ? (
                    <IconButton>
                      <KeyboardArrowUpIcon fontSize="small" />
                    </IconButton>
                  ) : (
                    <IconButton>
                      <CurrencyBitcoinIcon
                        fontSize="small"
                        sx={{ color: showToken ? "inherit" : "#868686" }}
                      />
                    </IconButton>
                  )}
                </Box>
                {showToken ? (
                  <Box
                    sx={{
                      marginBottom: {
                        mobile: "5px",
                        laptop: "5px",
                        desktop: "10px",
                      },
                      marginTop: {
                        mobile: "5px",
                        laptop: "5px",
                        desktop: "20px",
                      },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          mobile: "1rem",
                          laptop: "0.8rem",
                          desktop: "1rem",
                        },
                      }}
                      gutterBottom
                    >
                      Buy and Stake BORING and automatically earn BORING tokens
                      every time you:
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                        flexWrap: "wrap",
                        gap: 0.5,
                        marginBottom: {
                          mobile: "5px",
                          laptop: "15px",
                          desktop: "20px",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          fontSize: {
                            mobile: "0.8rem",
                            laptop: "0.7rem",
                            desktop: "0.8rem",
                          },
                        }}
                      >
                        <DiamondIcon
                          sx={{ marginRight: "10px" }}
                          fontSize="small"
                        />
                        Post
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          fontSize: {
                            mobile: "0.8rem",
                            laptop: "0.7rem",
                            desktop: "0.8rem",
                          },
                        }}
                      >
                        <DiamondIcon
                          sx={{ marginRight: "10px" }}
                          fontSize="small"
                        />
                        Like/Dislike
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          fontSize: {
                            mobile: "0.8rem",
                            laptop: "0.7rem",
                            desktop: "0.8rem",
                          },
                        }}
                      >
                        <DiamondIcon
                          sx={{ marginRight: "10px" }}
                          fontSize="small"
                        />
                        Comment
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: { laptop: "row", desktop: "row" },
                        flexWrap: "wrap",
                        gap: { mobile: 1, laptop: 1, desktop: 0.5 },
                        alignItems: "center",
                      }}
                    >
                      <Button disabled variant="contained">
                        Coming soon
                      </Button>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
              </Box>
            </Card>
          ) : (
            <></>
          )}
        </Box>

        <Box sx={theme === darkTheme ? sxCommonFeed : sxCommonFeedLight}>
          <Box
            sx={{
              marginBottom: { mobile: "10px", laptop: "40px", desktop: "50px" },
              display: "flex",
              alignItems: "top",
              width: "100%",
            }}
          >
            <Box
              ref={ref}
              sx={{
                border: "0px solid yellow",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                zIndex: 14,
                width: "100%",
                alignItems: "top",
              }}
            >
              {showCardWrite ? (
                <Card
                  id="postCard"
                  variant={showWrite ? undefined : undefined}
                  sx={{
                    boxShadow: {
                      mobile: `${theme.palette.primary.main}4D 0px 5px, ${theme.palette.primary.main}33 0px 10px, ${theme.palette.primary.main}1D 0px 15px, ${theme.palette.primary.main}0D 0px 20px`,
                      laptop: showWrite
                        ? `${theme.palette.primary.main}66 -5px 5px, ${theme.palette.primary.main}4D -10px 10px, ${theme.palette.primary.main}33 -15px 15px, ${theme.palette.primary.main}1A -20px 20px, ${theme.palette.primary.main}0D -25px 25px;`
                        : "inherit",
                    },
                    padding: { mobile: "10px", laptop: "15px" },
                    boxSizing: "border-box",
                    width: { mobile: "100%", laptop: "100%", desktop: "100%" },
                    maxWidth: { mobile: "100%", laptop: "650px" },
                    marginBottom: { mobile: "20px", tablet: "0px" },
                  }}
                >
                  <Box
                    onClick={() => {
                      if (window.innerWidth < 640) {
                        setShowWrite(!showWrite);
                      }
                    }}
                    sx={{
                      width: "100%",
                      marginBottom: {
                        mobile: "0px",
                        laptop: "5px",
                        desktop: "0px",
                      },
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "MedievalSharp",
                        fontSize: {
                          mobile: "1.1rem",
                          laptopt: "1.2rem",
                          desktop: "1.5rem",
                        },
                        color: showWrite ? "inherit" : "#868686",
                      }}
                    >
                      <b>Create a new post</b>
                    </Typography>
                    {showWrite ? (
                      <IconButton>
                        <KeyboardArrowUpIcon fontSize="small" />
                      </IconButton>
                    ) : (
                      <IconButton>
                        <ForumSharpIcon
                          fontSize="small"
                          sx={{ color: showWrite ? "inherit" : "#868686" }}
                        />
                      </IconButton>
                    )}
                  </Box>
                  {showWrite ? (
                    <Box>
                      <Box sx={{ marginBottom: "10px", marginTop: "10px" }}>
                        <TextField
                          fullWidth
                          inputRef={valueRef}
                          multiline={true}
                          maxRows={10}
                          id="outlined-basic"
                          inputProps={{ maxLength: maxPostSize }}
                          variant="outlined"
                          label={
                            user.isConnected
                              ? "Feel like a modern peasant? Say it!"
                              : "Please connect your wallet first"
                          }
                          disabled={!user.isConnected}
                          color={user.isConnected ? "primary" : "error"}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            width: { mobile: "80%", laptop: "85%" },
                            m: 1,
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}
                        >
                          {feedCategories.map((cat: string, index: number) => {
                            return (
                              <Chip
                                key={index}
                                size="small"
                                label={cat}
                                sx={{
                                  fontSize: {
                                    mobile: "0.7rem",
                                    laptop: "0.7rem",
                                    desktop: "0.8rem",
                                  },
                                }}
                                color={
                                  postCategories.includes(cat)
                                    ? "primary"
                                    : "default"
                                }
                                onClick={handleClick}
                              />
                            );
                          })}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { mobile: "row", laptop: "column" },
                            width: { mobile: "20%", laptop: "15%" },
                            justifyContent: "right",
                            alignItems: "center",
                            gap: "15px",
                          }}
                        >
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={handleNewPost}
                            disabled={!user.isConnected}
                          >
                            Post
                          </Button>
                        </Box>
                      </Box>
                      <Box>
                        {alertCategories ? (
                          <Typography
                            sx={{
                              fontSize: { mobile: "0.8rem", laptop: "1rem" },
                              color: "#FF6666",
                            }}
                          >
                            Please select at least one category above...
                          </Typography>
                        ) : (
                          <></>
                        )}
                        {alertContent ? (
                          <Typography
                            sx={{
                              fontSize: { mobile: "0.8rem", laptop: "1rem" },
                              color: "#FF6666",
                            }}
                          >
                            Please make your post longer...
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Box>
                  ) : (
                    <></>
                  )}
                </Card>
              ) : (
                <></>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: { mobile: "block", laptop: "block" },
              width: "100%",
              marginBottom: "30px",
              border: "0px solid yellow",
            }}
          >
            <Divider>
              <Chip
                label={
                  <Typography sx={{ fontFamily: "MedievalSharp" }}>
                    Feed
                  </Typography>
                }
                size="small"
              />
            </Divider>
          </Box>

          <Box
            sx={{
              border: "0px solid yellow",
              width: { mobile: "100%", laptop: "100%", desktop: "650px" },
              display: "flex",
            }}
          >
            <Box
              sx={{
                width: { mobile: "100%", laptop: "100%" },
                border: "0px solid red",
                maxWidth: { laptop: "100%" },
              }}
            >
              {posts.length === 1 ? (
                <></>
              ) : (
                <InfiniteScroll
                  dataLength={posts.length}
                  next={fetchMore}
                  hasMore={!fetchEnd}
                  refreshFunction={initialFetch}
                  style={{ overflowY: "hidden" }}
                  scrollableTarget="MuiBox-root css-0"
                  pullDownToRefresh
                  pullDownToRefreshThreshold={50}
                  pullDownToRefreshContent={
                    <Typography
                      sx={{ fontSize: "0.9rem", textAlign: "center" }}
                    >
                      &#8595; Pull down to refresh
                    </Typography>
                  }
                  loader={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  }
                  endMessage={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: { mobile: "0.8em", laptop: "0.8rem" } }}
                        gutterBottom
                      >
                        <i>
                          - You reached the end of the feed, come back later -
                        </i>
                      </Typography>
                    </Box>
                  }
                >
                  {posts.map((feedPost: any, index: any) => {
                    return <FeedPost key={index} post={feedPost} />;
                  })}
                </InfiniteScroll>
              )}
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {statusID !== 200 ? <ErrorHandler returnedError={statusID} /> : <></>}

          <Snackbar
            open={showPostSuccess}
            autoHideDuration={5000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            onClose={handleCloseShowPostSuccessSnack}
          >
            <Alert onClose={handleCloseShowPostSuccessSnack} severity="success">
              Post created! Congrats, you can now interact with your post!
            </Alert>
          </Snackbar>

          <Snackbar
            open={showPostError}
            autoHideDuration={5000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            onClose={handleCloseShowPostErrorSnack}
          >
            <Alert onClose={handleCloseShowPostErrorSnack} severity="error">
              {showPostErrorDesc}
            </Alert>
          </Snackbar>
        </Box>
      </Box>

      <Box
        sx={{
          "& > :not(style)": { m: 1 },
          position: "fixed",
          bottom: 16,
          right: 16,
          zIndex: 999,
        }}
      >
        <Fab variant="extended" onClick={handleClickPostFAB}>
          <ChatIcon sx={{ mr: 1 }} />
          <Typography>
            <b>POST</b>
          </Typography>
        </Fab>
      </Box>

      <Box>
        <Footer />
      </Box>
    </Box>
  );
};
