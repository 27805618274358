import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CancelIcon from "@mui/icons-material/Cancel";
import { Avatar, Divider, IconButton } from "@mui/material";
import axios from "axios";
import { useGlobalUserContext } from "./UserContext";
import Badge from "@mui/material/Badge";
import anon from "../data/anon.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useGlobalThemeContext } from "../components/home/ThemeContext";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LogoutIcon from "@mui/icons-material/Logout";
import ReactConfetti from "react-confetti";
import { APIUrl } from "../components/tools/APISource";
import { Link } from "react-router-dom";

const drawerBleeding = 0;

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#004AB9",
    color: "#004AB9",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export const ConnectWeb2 = () => {
  const [connectDrawerOpen, setConnectDrawerOpen] = useState(false);
  const [keyVisible, setKeyVisible] = useState<boolean>(false);
  const [showConfetti, setShowConfetti] = useState<boolean>(false);
  const [resultOpen, setResultOpen] = useState(false);
  const [connectDialogOpen, setConnectDialogOpen] = useState(false);
  const [disconnectDialogOpen, setDisconnectDialogOpen] = useState(false);
  const { user, setUser } = useGlobalUserContext();
  const { theme, setTheme } = useGlobalThemeContext();
  const [dialogData, setDialogData] = useState<any>({});
  const windowSize = React.useRef<any[]>([
    window.innerWidth,
    window.innerHeight,
  ]);

  const handleClickOpen = () => {
    setResultOpen(true);
  };
  const handleClickClose = () => {
    setResultOpen(false);
    setShowConfetti(false);
  };

  console.log("custom user", user);

  const toggleDrawer = (newOpen: boolean) => () => {
    setConnectDrawerOpen(newOpen);
  };

  const writeToClipboard = async (pk: string) => {
    await navigator.clipboard.writeText(pk);
  };

  const postAnon = async () => {
    const response = await axios.request({
      url: `${APIUrl}/anon`,
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    console.log("anon response", response);
    if (response.status === 200) {
      let anonUser = {
        address: response.data.public_key,
        isConnected: true,
        isAnonymous: true,
        isWeb2: true,
        isWeb3: false,
        score: 0,
      };
      let dialog = {
        privateKey: response.data.private_key,
        publicKey: response.data.public_key,
      };
      console.log("dialog", dialog);
      setUser(anonUser);
      setDialogData(dialog);
      return anonUser;
    } else {
      return user;
    }
  };

  // _________________________________________________________________________________________
  // ---------------------------------------- DISCONNECT -------------------------------------
  // _________________________________________________________________________________________

  // disconnect anon
  const disconnectAnon = async () => {
    try {
      const response = await axios.request({
        url: `${APIUrl}/disconnect`,
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      console.log("anon response", response);
      if (response.status === 200) {
        console.log("anon disconnected");
        return true;
      } else {
        console.log("error disconnecting");
        return false;
      }
    } catch (error: any) {
      console.log("error disconnecting");
      return false;
    }
  };

  const handleDisconnectAnon = async () => {
    let isSuccess = await disconnectAnon();
    if (isSuccess) {
      let anonUser = {
        address: "0x0000000000000000000000000000000000000000",
        isConnected: false,
        isAnonymous: true,
        isWeb2: false,
        isWeb3: false,
        score: 0,
      };
      setUser(anonUser);
      setDisconnectDialogOpen(false);
    }
  };

  const handleConnectAnon = () => {
    postAnon();
    if (windowSize.current[0] < 1080) {
      setConnectDrawerOpen(false);
    } else {
      setConnectDialogOpen(false);
    }
    setResultOpen(true);
    setShowConfetti(true);
  };

  const handleToggleConnect = () => {
    if (windowSize.current[0] < 1080) {
      setConnectDrawerOpen(true);
    } else {
      setConnectDialogOpen(true);
    }
  };

  useEffect(() => {
    if (!user.isConnected) {
      handleToggleConnect();
    }
  }, []);

  return (
    <Box>
      {showConfetti ? (
        <ReactConfetti gravity={0.05} numberOfPieces={200} />
      ) : (
        <></>
      )}
      {user.isConnected ? (
        <Button
          onClick={() => {
            setDisconnectDialogOpen(true);
          }}
          variant="contained"
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "5px",
            p: 1,
            textTransform: "none",
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.light,
            "&:hover": { backgroundColor: theme.palette.background.default },
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              variant="dot"
            >
              <Avatar sx={{ background: "black", width: 24, height: 24 }}>
                <img style={{ width: 20, height: 20 }} src={anon} />
              </Avatar>
            </StyledBadge>
            <Box sx={{ display: { mobile: "none", tablet: "flex" } }}>
              <Typography>
                <b>
                  {user.address.substr(0, 4)}...
                  {user.address.substr(user.address.length - 4)}
                </b>
              </Typography>
            </Box>
            <KeyboardArrowDownIcon />
          </Box>
        </Button>
      ) : (
        <Button
          onClick={handleToggleConnect}
          variant="contained"
          startIcon={
            <img style={{ width: "20px", height: "20px" }} src={anon} />
          }
          sx={{
            fontSize: "0.8rem",
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.primary.light,
            "&:hover": { backgroundColor: theme.palette.background.default },
          }}
        >
          Connect
        </Button>
      )}

      <SwipeableDrawer
        anchor="bottom"
        open={connectDrawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: false,
        }}
        PaperProps={{
          sx: {
            height: `550px`,
            top: `-${drawerBleeding}px)`,
            borderTopLeftRadius: 35,
            borderTopRightRadius: 35,
            background: theme.palette.background.default,
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: -drawerBleeding,
            right: 0,
            left: 0,
            background: "#000000",
          }}
        >
          <Box
            sx={{
              right: 0,
              left: 0,
              background: theme.palette.background.default,
              justifyContent: "center",
              p: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: "15px",
              }}
            >
              <Typography
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "1.4rem",
                }}
              >
                <b>Connect as Anon Peasant</b>
              </Typography>
              <IconButton
                onClick={() => {
                  setDisconnectDialogOpen(false);
                }}
              >
                <CancelIcon sx={{ color: "grey" }} />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: "50px",
                p: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleConnectAnon}
                  sx={{ borderRadius: 2, padding: 1 }}
                >
                  <img src={anon} width="35px" height="35px" />
                </Button>
                <Typography sx={{ fontSize: "0.9rem" }}>
                  <b>Anon</b>
                </Typography>
              </Box>
            </Box>
          </Box>

          <Divider
            sx={{
              bgcolor: theme.palette.background.default,
              borderBottomWidth: 1,
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 4,
              height: "100%",
              overflow: "auto",
              background: theme.palette.background.default,
              gap: "15px",
            }}
          >
            <Typography sx={{ fontSize: "1rem" }}>
              <b>What is an anonymous connection?</b>
            </Typography>
            <Typography
              sx={{
                fontSize: "0.8rem",
                color: grey[500],
                textAlign: "center",
                whiteSpace: "pre-wrap",
              }}
            >
              Nothing to do with hackers. By connecting as an anon, you get
              access to the app, just like your Web3 frens. The difference is
              that you get a 1 day access (that you can renew every day you want
              to connect). It also means you opt-out from any crypto reward you
              can get by using the app.
            </Typography>
            <Button sx={{ textTransform: "none" }} variant="contained">
              Learn more
            </Button>
          </Box>
        </Box>
      </SwipeableDrawer>

      {/* Notice dialog */}
      <Dialog
        onClose={handleClickClose}
        aria-labelledby="customized-dialog-title"
        open={resultOpen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: {
                mobile: "95%",
                laptop: "60%",
                desktop: "45%",
                largeDesktop: "30%",
              },
            },
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <b>Important notice</b>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ mb: "20px" }}>
            <Box sx={{ display: "flex", justifyContent: "center", mb: "10px" }}>
              <img src={anon} width="50px" height="50px" />
            </Box>
            <Typography
              sx={{ fontFamily: "MedievalSharp", fontSize: "0.8rem" }}
              gutterBottom
            >
              Welcome anon!
            </Typography>
            <Typography sx={{ fontSize: "0.8rem" }} gutterBottom>
              You just logged in as an anon, which means you can now interact
              with the app, just like anyone else.
            </Typography>
            <Typography sx={{ fontSize: "0.8rem" }} gutterBottom>
              As a result, you were assigned a random crypto key pair by our
              system. This key is shared here below{" "}
              <b>- once and only once! -</b> as it will <u>not</u> be stored on
              our end for security reasons. You are free to save this private
              key and reuse it later by importing it in a crypto wallet. This
              way, you will keep your progression on our protocol. If you do not
              do it, no problem, but we won't be able to get it back for you.
            </Typography>
          </Box>
          <Box>
            {keyVisible ? (
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { mobile: "column", tablet: "row" },
                    width: "100%",
                    verticalAlign: "middle",
                    alignItems: "center",
                    overflow: "auto",
                    whiteSpace: "nowrap",
                    mb: "10px",
                    gridGap: "10px",
                  }}
                >
                  <Button
                    variant="text"
                    size="small"
                    onClick={() => {
                      setKeyVisible(false);
                    }}
                    startIcon={<VisibilityOffIcon />}
                  >
                    Hide
                  </Button>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      whiteSpace: "normal",
                      mb: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        p: 1,
                        width: { mobile: "85%", laptop: "90%" },
                        backgroundColor: theme.palette.primary.light,
                        fontSize: "0.8rem",
                        overflowWrap: "break-word",
                      }}
                    >
                      {dialogData.privateKey}
                    </Typography>
                    <IconButton
                      sx={{ width: { mobile: "15%", laptop: "10%" } }}
                      onClick={() => {
                        writeToClipboard(dialogData.privateKey);
                      }}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    whiteSpace: "normal",
                    mb: "15px",
                  }}
                >
                  <Typography sx={{ fontSize: "0.8rem", color: "#FF6666" }}>
                    Warning: NEVER share this private key with anyone or you
                    will risk losing any funds present in the wallet!
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { mobile: "column", tablet: "row" },
                  overflow: "wrap",
                  gridGap: "10px",
                  mb: "15px",
                }}
              >
                <Button
                  variant="text"
                  size="small"
                  onClick={() => {
                    setKeyVisible(true);
                  }}
                  startIcon={<VisibilityIcon />}
                >
                  Show
                </Button>
                <Typography noWrap>
                  ***********************************
                </Typography>
              </Box>
            )}
          </Box>
          <Box>
            <Typography sx={{ fontSize: "0.8rem" }} gutterBottom>
              Finally, note that anons are granted access for a period of{" "}
              <u>3 days</u>. This means you will lose access to this account
              after{" "}
              {new Date(Date.now() + 3600 * 1000 * 24 * 3).toLocaleString()} if
              you do not save the above private key and reuse it in a crypto
              wallet.
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions>
          <Link
            target="_blank"
            to={
              "https://docs.boringday.xyz/getting-started/anonymous-connect-web2"
            }
          >
            <Button>More info</Button>
          </Link>
          <Button autoFocus onClick={handleClickClose}>
            Understood
          </Button>
        </DialogActions>
      </Dialog>

      {/* Laptop Connect Tab */}
      <Dialog
        onClose={handleClickClose}
        aria-labelledby="customized-dialog-title"
        open={connectDialogOpen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: { mobile: "99%", laptop: "50%", desktop: "40%" },
              backgroundColor: theme.palette.background.default,
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            background: theme.palette.background.default,
          }}
        >
          <Box
            sx={{
              background: theme.palette.background.default,
              justifyContent: "left",
              p: 2,
              width: "45%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "left",
                mb: "25px",
              }}
            >
              <Typography
                sx={{
                  flexGrow: 1,
                  display: "flex",
                  justifyContent: "left",
                  fontSize: "1.4rem",
                }}
              >
                <b>Connect as Anon</b>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                gap: "50px",
                p: "5px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <Button
                  variant="text"
                  onClick={handleConnectAnon}
                  sx={{
                    gridGap: "10px",
                    borderRadius: 2,
                    padding: 1,
                    border: "1px solid black",
                    textTransform: "none",
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.primary.light,
                  }}
                >
                  <img src={anon} width="35px" height="35px" />
                  <Typography sx={{ fontSize: "0.9rem" }}>
                    <b>Anon</b>
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              background: theme.palette.background.default,
              justifyContent: "left",
              p: 2,
              width: "5%",
            }}
          >
            <Divider orientation="vertical" />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 2,
              height: "100%",
              overflow: "auto",
              background: theme.palette.background.default,
              gap: "20px",
              width: "55%",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
              <IconButton
                onClick={() => {
                  setConnectDialogOpen(false);
                }}
              >
                <CancelIcon />
              </IconButton>
            </Box>
            <Box sx={{}}>
              <Typography sx={{ textAlign: "center", fontSize: "1rem" }}>
                <b>What is an anonymous connection?</b>
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: "0.8rem",
                color: theme.palette.text.primary,
                textAlign: "center",
                whiteSpace: "pre-wrap",
              }}
            >
              Nothing to do with hackers. By connecting as an anon, you get
              access to the app, just like your Web3 frens. The difference is
              that you get a 3 days access (that you can renew every day you
              want to connect). It also means you opt-out from any crypto reward
              you can get by using the app.
            </Typography>

            <Link
              to={`https://docs.boringday.xyz/getting-started/anonymous-connect-web2`}
            >
              <Button sx={{ width: "100%" }} variant="contained">
                Learn more
              </Button>
            </Link>
          </Box>
        </Box>
      </Dialog>

      {/* Disconnect Dialog */}
      <Dialog
        onClose={handleClickClose}
        aria-labelledby="customized-dialog-title"
        open={disconnectDialogOpen}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: { mobile: "90%", laptop: "25%", desktop: "25%" },
              backgroundColor: theme.palette.background.default,
              border: "1px solid " + theme.palette.background.default,
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            p: 2,
            width: "100%",
            flexDirection: "column",
            background: theme.palette.background.default,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <IconButton
              onClick={() => {
                setDisconnectDialogOpen(false);
              }}
            >
              <CancelIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              background: theme.palette.background.default,
              alignItems: "center",
              gridGap: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                background: theme.palette.background.default,
                alignItems: "center",
                gridGap: "15px",
              }}
            >
              <img style={{ width: "50px", height: "50px" }} src={anon} />
              <Typography>
                <b>
                  {user.address.substr(0, 4)}...
                  {user.address.substr(user.address.length - 4)}
                </b>
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                background: theme.palette.background.default,
              }}
            >
              <Button
                onClick={handleDisconnectAnon}
                variant="contained"
                startIcon={<LogoutIcon />}
              >
                Disconnect
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                background: theme.palette.background.default,
                width: { mobile: "100%", laptop: "90%", desktop: "60%" },
              }}
            >
              <Typography sx={{ fontSize: "0.8rem", color: "#FF6666" }}>
                Warning: if you disconnect your anon profile, you won't be able
                to get it back unless you saved the private key when logging in
                the first time! (note: we can't get it back for you, so it might
                be too late anyway...)
              </Typography>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};
