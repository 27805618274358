import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
    largeDesktop: true;
  }
}

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#000000",
    },
    primary: {
      main: "#FFF7A8",
      light: "#000000",
    },
    secondary: {
      main: "#656565",
    },
    text: {
      primary: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "Monospace",
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1440,
      largeDesktop: 2160,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#2b2b2b",
            width: "0.4rem",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 24,
            border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
          scrollbarWidth: "thin",
          backgroundColor: "#2b2b2b",
          backgroundImage: `linear-gradient(357deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 100%)`,
        },
      },
    },
  },
});

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#E2E2E2",
    },
    primary: {
      main: "#A8B0FF",
      light: "#FFFFFF",
    },
    secondary: {
      main: "#000000",
    },
    text: {
      primary: "#000000",
    },
  },
  typography: {
    fontFamily: "Monospace",
  },
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1440,
      largeDesktop: 2160,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "#2b2b2b",
            width: "0.4rem",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "#6b6b6b",
            minHeight: 24,
            border: "3px solid #2b2b2b",
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "#959595",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "#2b2b2b",
          },
          scrollbarWidth: "thin",
        },
      },
    },
  },
});

export const sxCommonPage = {
  flexDirection: { mobile: "row", md: "column" },
  border: "1px solid transparent",
  flexWrap: "wrap",
  marginTop: { mobile: "10px", laptop: "5px", desktop: "25px" },
  marginLeft: {
    mobile: "5%",
    laptop: "15%",
    desktop: "20%",
    largeDesktop: "25%",
  },
  marginRight: {
    mobile: "5%",
    laptop: "15%",
    desktop: "20%",
    largeDesktop: "25%",
  },
  pointerEvent: "none",
};

export const sxCommonFooter = {
  flexDirection: { mobile: "row", md: "column" },
  border: "1px solid transparent",
  flexWrap: "wrap",
  marginTop: { mobile: "10px", laptop: "10px", desktop: "10px" },
  marginLeft: { mobile: "1%", tablet: "10%", laptop: "25%", desktop: "35%" },
  marginRight: { mobile: "1%", tablet: "10%", laptop: "25%", desktop: "35%" },
  zIndex: 9,
};

export const sxCommonFeed = {
  display: "flex",
  flexDirection: { mobile: "row", laptop: "column" },
  borderLeft: { laptop: "1px solid #202020" },
  borderRight: { laptop: "1px solid #202020" },
  flexWrap: "wrap",
  marginTop: { mobile: "0px", laptop: "6%", desktop: "5%" },
  marginLeft: { mobile: "0%", laptop: "30px", desktop: "50px" },
  marginRight: { mobile: "0%", laptop: "30px", desktop: "50px" },
  width: { mobile: "100%", laptop: "50%", desktop: "65%" },
  zIndex: 10,
  paddingLeft: { mobile: "0px", laptop: "30px", desktop: "50px" },
  paddingRight: { mobile: "0px", laptop: "30px", desktop: "50px" },
  alignItems: "center",
};

export const sxCommonFeedLight = {
  display: "flex",
  flexDirection: { mobile: "row", laptop: "column" },
  borderLeft: { laptop: "1px solid #C4C4C4" },
  borderRight: { laptop: "1px solid #C4C4C4" },
  flexWrap: "wrap",
  marginTop: { mobile: "0px", laptop: "6%", desktop: "5%" },
  marginLeft: { mobile: "0%", laptop: "30px", desktop: "50px" },
  marginRight: { mobile: "0%", laptop: "30px", desktop: "50px" },
  width: { mobile: "100%", laptop: "50%", desktop: "65%" },
  zIndex: 10,
  paddingLeft: { mobile: "0px", laptop: "30px", desktop: "50px" },
  paddingRight: { mobile: "0px", laptop: "30px", desktop: "50px" },
  alignItems: "center",
};

export const sxCommonUser = {
  display: "flex",
  flexDirection: { mobile: "column", laptop: "column" },
  border: "0px solid purple",
  flexWrap: "wrap",
  marginTop: { mobile: "75px", laptop: "50px", desktop: "5%" },
  marginLeft: { mobile: "0%", laptop: "30px", desktop: "50px" },
  marginRight: { mobile: "0%", laptop: "30px", desktop: "50px" },
  width: { mobile: "95%", laptop: "65%", desktop: "65%" },
  zIndex: 10,
  alignItems: "center",
};

export const sxCommonWallet = {
  display: "flex",
  flexDirection: { mobile: "column", laptop: "column" },
  border: "0px solid purple",
  flexWrap: "wrap",
  marginTop: { mobile: "85px", laptop: "25px", desktop: "10px" },
  width: { mobile: "95%", laptop: "65%" },
  zIndex: 10,
  padding: { mobile: "0px", laptop: "50px" },
  marginLeft: { mobile: "2.5%", laptop: "0px" },
  marginRight: { mobile: "2.5%", laptop: "0px" },
  boxSizing: "border-box",
  alignItems: "center",
};

export const sxCommonLevel2 = {
  display: "flex",
  flexDirection: { mobile: "row", tablet: "column" },
  border: "0px solid green",
  marginTop: "1%",
  marginBottom: "1%",
  width: { mobile: "100%", tablet: "100%" },
  zIndex: 1,
};
