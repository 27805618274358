import * as React from "react";
import {
  Box,
  Card,
  Typography,
  CardHeader,
  CardContent,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useGlobalThemeContext } from "./ThemeContext";

export const ABD = () => {
  const { theme, setTheme } = useGlobalThemeContext();

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          flexDirection: { mobile: "column", tablet: "column", laptop: "row" },
          display: "flex",
          border: "0px solid yellow",
          marginBottom: { mobile: "10px", tablet: "0px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              mobile: "column",
              tablet: "column",
              laptop: "row",
            },
          }}
        >
          <Card
            variant="outlined"
            sx={{
              boxShadow: {
                mobile: `${theme.palette.primary.main}4D 0px 5px, ${theme.palette.primary.main}33 0px 10px, ${theme.palette.primary.main}1D 0px 15px, ${theme.palette.primary.main}0D 0px 20px`,
                laptop: `${theme.palette.primary.main}66 -5px 5px, ${theme.palette.primary.main}4D -10px 10px, ${theme.palette.primary.main}33 -15px 15px, ${theme.palette.primary.main}1A -20px 20px, ${theme.palette.primary.main}0D -25px 25px;`,
              },
              flexDirection: { mobile: "column", tablet: "row" },
              width: { mobile: "100%", laptop: "70%" },
              padding: "10px",
              boxSizing: "border-box",
            }}
          >
            <CardHeader
              subheader={
                <Typography
                  sx={{
                    color: "#A5A5A5",
                    fontFamily: "Monospace",
                    fontSize: { mobile: "1.1rem", laptop: "1rem" },
                  }}
                >
                  <b>By the bored workers, for the bored workers</b>
                </Typography>
              }
              title={
                <Typography
                  sx={{
                    fontFamily: "MedievalSharp",
                    fontSize: {
                      mobile: "1.4rem",
                      laptop: "1.3rem",
                      desktop: "3rem",
                    },
                  }}
                >
                  <b>A Boring Day</b>
                </Typography>
              }
            />
            <CardContent
              sx={{
                flexDirection: { mobile: "column", tablet: "column" },
                display: "flex",
              }}
            >
              <Box sx={{ marginBottom: "10px" }}>
                <Typography
                  sx={{
                    fontFamily: "MedievalSharp",
                    fontSize: {
                      mobile: "1.1rem",
                      laptop: "0.9rem",
                      desktop: "1.2rem",
                    },
                    marginBottom: { mobile: "8px", laptop: "10px" },
                  }}
                >
                  <i>A Boring Day</i> is the only place on the blockchain where
                  you can say everything you could probably not say to your
                  boss. And someone will actually enjoy what you have to
                  complain about.
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "MedievalSharp",
                    fontSize: {
                      mobile: "1.1rem",
                      laptop: "0.9rem",
                      desktop: "1.2rem",
                    },
                    marginBottom: { mobile: "8px", laptop: "10px" },
                  }}
                >
                  Tell your most boring corporate stories out loud.
                </Typography>
                <Box
                  sx={{
                    fontFamily: "MedievalSharp",
                    fontSize: {
                      mobile: "1.1rem",
                      laptop: "0.9rem",
                      desktop: "1.2rem",
                    },
                    marginBottom: { mobile: "8px", laptop: "10px" },
                    pointerEvents: "auto",
                  }}
                >
                  <Link to={"./feed"}>
                    <Button variant="outlined">Go to Feed</Button>
                  </Link>
                </Box>
                <Typography
                  sx={{
                    fontFamily: "MedievalSharp",
                    fontSize: {
                      mobile: "1.1rem",
                      laptop: "0.9rem",
                      desktop: "1.2rem",
                    },
                  }}
                >
                  We hear you.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Box>
      <Box></Box>
    </React.Fragment>
  );
};
