import * as React from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useGlobalExplainContext } from "./ExplainContext";

export const Explain = () => {
  const handleClose = () => setExplain(false);
  const { explain, setExplain } = useGlobalExplainContext();

  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={explain}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: {
                mobile: "95%",
                laptop: "55%",
                desktop: "45%",
                largeDesktop: "30%",
              },
            },
          },
        }}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <b>Important read me</b>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ mb: "20px" }}>
            <Typography
              sx={{ fontFamily: "MedievalSharp", fontSize: "0.8rem" }}
              gutterBottom
            >
              Welcome to our Boring feed!
            </Typography>
            <Typography sx={{ fontSize: "0.8rem" }} gutterBottom>
              This is the place where you will be able to read the most boring
              corporate stories ever experienced. You are obviously more than
              welcomed to share your own corporate stories and let other people
              enjoy and react.
            </Typography>
            <Typography sx={{ fontSize: "0.8rem" }} gutterBottom>
              Please make sure what everything you post or comment respects our
              rules. If you see anything suspicious or inapropriate, also do not
              hesitate to report with the "report" button on every post.
            </Typography>
            <Typography sx={{ fontSize: "0.8rem" }} gutterBottom>
              Finally, if you are not connected yet, you probably should because
              you won't be able to do anything but read if you don't. You can do
              it either by connecting a crypto wallet (just two clicks: no
              access required, no gas fee, just a single signature to prove you
              are the true owner), either by connecting as an anon (nothing to
              do, you'll be connected directly but will not be able to enjoy
              rewards afterwards).
            </Typography>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Understood
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
