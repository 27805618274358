import React, { useState, useEffect } from "react";
import { Box, IconButton, Button } from "@mui/material";
import axios from "axios";
import { ConnectWeb3 } from "./customWeb3";
import { ConnectWeb2 } from "./customWeb2";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useGlobalThemeContext } from "../components/home/ThemeContext";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import metamask from "../data/metamask.png";
import anon from "../data/anon.png";
import LockIcon from "@mui/icons-material/Lock";
import { useGlobalUserContext } from "./UserContext";
import { grey } from "@mui/material/colors";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { APIUrl } from "../components/tools/APISource";

const getUser = async (address: string) => {
  const response = await axios.request({
    url: `${APIUrl}/user/${address}`,
    method: "GET",
    headers: { Accept: "application/json", "Content-Type": "application/json" },
    data: JSON.stringify({ address }),
    withCredentials: true,
  });

  return response.data;
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export const Connector = () => {
  const { user, setUser } = useGlobalUserContext();
  const [isWeb3, setIsWeb3] = useState<boolean>(false);
  const [isWeb2, setIsWeb2] = useState<boolean>(false);
  const { theme, setTheme } = useGlobalThemeContext();

  useEffect(() => {
    console.log("connector user", user);
    setIsWeb2(user.isConnected && user.isWeb2);
    setIsWeb3(user.isConnected && user.isWeb3);
  }, [user]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsWeb3(!isWeb3);
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClickClose = () => {
    setDialogOpen(false);
  };

  const switchConnector = () => {
    setIsWeb2(!isWeb2);
    setIsWeb3(!isWeb3);
  };

  const handleClickWeb2Connect = () => {
    setDialogOpen(false);
    setIsWeb2(true);
    setIsWeb3(false);
  };

  const handleClickWeb3Connect = () => {
    setDialogOpen(false);
    setIsWeb3(true);
    setIsWeb2(false);
  };

  return (
    <Box>
      {!isWeb2 && !isWeb3 ? (
        <Box>
          <Button
            onClick={handleClickOpen}
            variant="contained"
            startIcon={<LockIcon />}
            sx={{}}
          >
            <b>Connect</b>
          </Button>

          <Dialog
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: { mobile: "90%", laptop: "35%" },
                  backgroundColor: theme.palette.background.default,
                  border: "1px solid " + grey[900],
                },
              },
            }}
            onClose={handleClickClose}
            aria-labelledby="customized-dialog-title"
            open={dialogOpen}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              <b>Connection</b>
            </DialogTitle>

            <IconButton
              aria-label="close"
              onClick={handleClickClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>

            <DialogContent dividers>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mb: 2,
                  gridGap: "8px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mb: 2,
                    gridGap: "8px",
                    width: { mobile: "100%", laptop: "100%" },
                  }}
                >
                  <Button
                    onClick={handleClickWeb3Connect}
                    variant="contained"
                    startIcon={
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={metamask}
                      />
                    }
                    sx={{
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.primary.light,
                      "&:hover": {
                        backgroundColor: theme.palette.background.default,
                      },
                    }}
                  >
                    Crypto Connect
                  </Button>
                  <Typography
                    sx={{ fontSize: "0.9rem", textAlign: "center" }}
                    gutterBottom
                  >
                    Connect 20+ crypto wallet (MetaMask, TrustWallet, Rainbow,
                    Base, ...) in just 2 clicks.
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mb: 2,
                    gridGap: "8px",
                    width: { mobile: "100%", laptop: "100%" },
                  }}
                >
                  <Button
                    onClick={handleClickWeb2Connect}
                    variant="contained"
                    startIcon={
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={anon}
                      />
                    }
                    sx={{
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.primary.light,
                      "&:hover": {
                        backgroundColor: theme.palette.background.default,
                      },
                    }}
                  >
                    Anon Connect
                  </Button>
                  <Typography
                    sx={{ fontSize: "0.9rem", textAlign: "center" }}
                    gutterBottom
                  >
                    Get a random temporary public key and connect to the
                    protocol as anon, without even have to input your email
                    address (note: you will not be able to accumulate rewards or
                    progression unless you crypto connect)
                  </Typography>
                </Box>
              </Box>
            </DialogContent>

            <DialogActions>
              <Button autoFocus onClick={handleClickClose}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      ) : (
        <Box
          sx={{ display: "flex", flexDirection: "row", textAlign: "center" }}
        >
          {!user.isConnected ? (
            <IconButton onClick={switchConnector}>
              <ChangeCircleIcon fontSize="small" />
            </IconButton>
          ) : (
            <></>
          )}
          {isWeb2 || (user.isConnected && user.isWeb2) ? (
            <ConnectWeb2 />
          ) : (
            <></>
          )}
          {isWeb3 || (user.isConnected && user.isWeb3) ? (
            <ConnectWeb3 />
          ) : (
            <></>
          )}
        </Box>
      )}
    </Box>
  );
};
