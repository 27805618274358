import * as React from "react";
import { Box, Typography, Card, CardHeader, CardContent } from "@mui/material";
import wtf from "../../data/wtf.png";

export const Peasant = () => {
  return (
    <React.Fragment>
      <Box
        sx={{
          flexDirection: {
            mobile: "column",
            tablet: "column-reverse",
            laptop: "row-reverse",
          },
          display: "flex",
          border: "0px solid yellow",
          pointerEvent: "none",
        }}
      >
        <Card
          variant="outlined"
          sx={{
            flexDirection: {
              mobile: "column",
              tablet: "column",
              laptop: "row",
            },
            display: "flex",
            alignItems: "center",
            gridGap: "25px",
            padding: "15px",
            boxSizing: "border-box",
            width: "100%",
          }}
        >
          <Box sx={{ width: { mobile: "100%", tablet: "75%", laptop: "40%" } }}>
            <img src={wtf} width="100%" style={{ borderRadius: "10px" }} />
            <Typography
              sx={{
                fontSize: {
                  mobile: "0.7rem",
                  laptop: "0.75rem",
                  desktop: "0.8rem",
                },
                textAlign: "center",
              }}
              gutterBottom
            >
              <i>The modern peasant</i>, Not Pieter Brueghel's self-portrait
            </Typography>
          </Box>
          <Box
            sx={{
              flexDirection: { mobile: "row", tablet: "row" },
              width: { mobile: "100%", laptop: "60%" },
              marginBottom: "0px",
            }}
          >
            <CardHeader
              subheader={
                <Typography sx={{ fontFamily: "Monospace", color: "#A5A5A5" }}>
                  <b>January, 1569</b>
                </Typography>
              }
              title={
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { mobile: "row", tablet: "row" },
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "MedievalSharp",
                      fontSize: {
                        mobile: "1.2rem",
                        tablet: "1.3rem",
                        desktop: "2rem",
                      },
                      marginRight: "10px",
                    }}
                    gutterBottom
                  >
                    <b>The story of Not Pieter Brueghel</b>
                  </Typography>
                </Box>
              }
            />
            <CardContent>
              <Box>
                <Typography
                  sx={{
                    fontSize: {
                      mobile: "1.1rem",
                      laptop: "0.9rem",
                      desktop: "1.3rem",
                    },
                    marginBottom: { mobile: "15px", laptop: "20px" },
                  }}
                  gutterBottom
                >
                  Pieter was a hard-working peasant. But a rainy day of the year
                  1568, he decided to sell his farm and beloved animals.
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      mobile: "1.1rem",
                      laptop: "0.9rem",
                      desktop: "1.3rem",
                    },
                    marginBottom: { mobile: "15px", laptop: "20px" },
                  }}
                  gutterBottom
                >
                  For several months, Pieter was harassed by dozens of itinerant
                  HR consultants. And, one day, he gave in. He was offered a
                  permanent contract within the grandest company in the entire
                  country: the promise of his own desk with its own double
                  screen, free but disgusting coffee, chair massage, the
                  vertical hierarchy and the 9-to-5 he thought he had been
                  looking for his entire life. Something he simply could not
                  resist. He signed.
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      mobile: "1.1rem",
                      laptop: "0.9rem",
                      desktop: "1.3rem",
                    },
                    marginBottom: { mobile: "15px", laptop: "20px" },
                  }}
                  gutterBottom
                >
                  Pure ecstasy, <s>undoubtedly</s>.
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      mobile: "1.1rem",
                      laptop: "0.9rem",
                      desktop: "1.3rem",
                    },
                  }}
                >
                  Unfortunately, corporate life was not exactly what Pieter had
                  expected. A couple months later, bored to death, he decided he
                  would do whatever it costs to escape it and help people live
                  free of this burden, forever.
                </Typography>
              </Box>
            </CardContent>
          </Box>
        </Card>
      </Box>
    </React.Fragment>
  );
};
