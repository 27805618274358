import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import { Fab, IconButton, Paper, Typography } from "@mui/material";
import { darkTheme, sxCommonPage } from "../customFormat";
import { Roadmap } from "./Roadmap";
import { Social } from "./Social";
import Marquee from "react-fast-marquee";
import { Peasant } from "./Peasant";
import { Tired } from "./Tired";
import { Legacy } from "./Legacy";
import { Footer } from "./Footer";
import { SocialBubblesWhite } from "./SocialBubblesWhite";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ABD } from "./ABD";
import { useGlobalThemeContext } from "./ThemeContext";
import ChatIcon from "@mui/icons-material/Chat";
import { Link } from "react-router-dom";
import { Loading } from "../tools/Loading";
import harvester from "../../data/harvester_crop.jpg";

export const Home = () => {
  const [init, setInit] = useState(true);
  const windowSize = useRef<any[]>([window.innerWidth, window.innerHeight]);
  const { theme, setTheme } = useGlobalThemeContext();

  const ref = useRef<null | HTMLDivElement>(null);
  const ref2 = useRef<null | HTMLDivElement>(null);
  const ref3 = useRef<null | HTMLDivElement>(null);
  const ref4 = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleArrowClick = () => {
    if (ref.current) {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleArrowClick2 = () => {
    if (ref2.current) {
      ref2.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleArrowClick3 = () => {
    if (ref3.current) {
      ref3.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const handleArrowClick4 = () => {
    if (ref4.current) {
      ref4.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const renderVideo = () => {
    if (window.innerWidth < 640) {
      return (
        <video
          controlsList="nodownload nofullscreen noremoteplayback"
          src={harvester}
          style={{
            objectPosition: "70%",
            filter: `brightness(50%) saturate(1)`,
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            top: 0,
            overflow: "hidden",
            zIndex: -999999,
          }}
          autoPlay
          muted
          loop
        />
      );
    } else if (window.innerWidth < 1024) {
      return (
        <video
          controlsList="nodownload nofullscreen noremoteplayback"
          src={harvester}
          style={{
            objectPosition: "20%",
            filter: `brightness(50%) saturate(1)`,
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            top: 0,
            overflow: "hidden",
            zIndex: -999999,
          }}
          autoPlay
          muted
          loop
        />
      );
    } else {
      return (
        <video
          controlsList="nodownload nofullscreen noremoteplayback"
          src={harvester}
          style={{
            objectPosition: "50%",
            filter: `brightness(50%) saturate(1)`,
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            top: 0,
            overflow: "hidden",
            zIndex: -999999,
          }}
          autoPlay
          muted
          loop
        />
      );
    }
  };

  if (init) {
    return (
      <React.Fragment>
        <Box
          sx={{
            maxWidth: "100%",
            marginTop: { mobile: "0%", laptop: "0%" },
          }}
        >
          <Box
            sx={{
              border: "1px solid transparent",
              height: "100vh",
              backgroundImage: `url(${harvester})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            {/* layer 1 */}
            {/* <Box
              sx={{
                display: "flex",
                marginTop: { laptop: "0%", desktop: "0%" },
              }}
            >
              {renderVideo()}
            </Box> */}

            {/* layer 2 */}
            <Box sx={sxCommonPage}>
              <Box sx={{ marginTop: { mobile: "20%", laptop: "10%" } }}>
                <SocialBubblesWhite />
              </Box>
            </Box>

            {/* layer 3 */}
            <Box sx={sxCommonPage}>
              <Box
                sx={{
                  marginTop: { mobile: "20%", tablet: "3%", laptop: "3%" },
                }}
              >
                <Tired />
              </Box>
            </Box>
          </Box>

          {/* layer 8 */}
          <Box
            sx={{
              position: "relative",
              display: { mobile: "flex", tablet: "flex", laptop: "none" },
              alignItems: "center",
              color: "white",
              background: theme === darkTheme ? "black" : "white",
              height: "45px",
              alignContent: "center",
              border: "0px solid green",
              zIndex: 10,
            }}
          >
            <Marquee
              autoFill
              direction="left"
              style={{ color: theme === darkTheme ? "white" : "black" }}
            >
              <b>
                STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP
                THE BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE!
              </b>
            </Marquee>
          </Box>

          {/* layer 4 */}
          <Box sx={sxCommonPage}>
            <Box
              sx={{
                position: { mobile: "relative", laptop: "absolute" },
                top: { laptop: "12%" },
                left: { laptop: "60%" },
                marginTop: {
                  mobile: "0%",
                  laptop: "5%",
                  desktop: "5%",
                  largeDesktop: "2%",
                },
                pointerEvent: "none",
              }}
            >
              <ABD />
            </Box>
          </Box>

          {/* layer 8 */}
          <Box
            sx={{
              position: "relative",
              marginTop: "20px",
              display: { mobile: "flex", tablet: "flex", laptop: "flex" },
              alignItems: "center",
              color: "white",
              background: theme === darkTheme ? "black" : "white",
              height: "45px",
              alignContent: "center",
              border: "0px solid green",
              zIndex: 10,
            }}
          >
            <Marquee
              direction="right"
              style={{ color: theme === darkTheme ? "white" : "black" }}
            >
              <b>
                STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP
                THE BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE!
              </b>
            </Marquee>
          </Box>

          {/* layer 6 */}
          <Box sx={sxCommonPage}>
            <Box
              sx={{
                display: { mobile: "none", laptop: "flex" },
                flexDirection: { mobile: "column", tablet: "column" },
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                border: "0px solid orange",
                marginTop: { laptop: "1%", desktop: "0%" },
              }}
            >
              <IconButton onClick={handleArrowClick}>
                <KeyboardArrowDownIcon
                  sx={{
                    fontSize: "5rem",
                    pointerEvents: "auto",
                    color: "#3A3A3A",
                  }}
                />
              </IconButton>
            </Box>
          </Box>

          {/* layer 7 */}
          <Box ref={ref} sx={sxCommonPage}>
            <Box sx={{ position: "relative" }}>
              <Peasant />
            </Box>
          </Box>

          {/* layer 8 */}
          <Box
            sx={{
              position: "relative",
              marginTop: "20px",
              display: { mobile: "flex", tablet: "flex", laptop: "flex" },
              alignItems: "center",
              color: "white",
              background: theme === darkTheme ? "black" : "white",
              height: "45px",
              alignContent: "center",
              border: "0px solid green",
              zIndex: 10,
            }}
          >
            <Marquee
              direction="left"
              style={{ color: theme === darkTheme ? "white" : "black" }}
            >
              <b>
                STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP
                THE BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE!
              </b>
            </Marquee>
          </Box>

          {/* layer 7b */}
          <Box sx={sxCommonPage}>
            <Box
              sx={{
                display: { mobile: "none", laptop: "flex" },
                flexDirection: { mobile: "column", tablet: "column" },
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                border: "0px solid orange",
                marginTop: { laptop: "5%", desktop: "0%", largeDesktop: "0%" },
              }}
            >
              <IconButton onClick={handleArrowClick2}>
                <KeyboardArrowDownIcon
                  sx={{
                    fontSize: "5rem",
                    pointerEvents: "auto",
                    color: "#3A3A3A",
                  }}
                />
              </IconButton>
            </Box>
          </Box>

          {/* layer 9 */}
          <Box ref={ref2} sx={sxCommonPage}>
            <Box sx={{ position: "relative" }}>
              <Legacy />
            </Box>
          </Box>

          {/* layer 8 */}
          <Box
            sx={{
              position: "relative",
              marginTop: "20px",
              display: { mobile: "flex", tablet: "flex", laptop: "flex" },
              alignItems: "center",
              color: "white",
              background: theme === darkTheme ? "black" : "white",
              height: "45px",
              alignContent: "center",
              border: "0px solid green",
              zIndex: 10,
            }}
          >
            <Marquee
              direction="right"
              style={{ color: theme === darkTheme ? "white" : "black" }}
            >
              <b>
                STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP
                THE BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE!
              </b>
            </Marquee>
          </Box>

          {/* layer 9b */}
          <Box sx={sxCommonPage}>
            <Box
              sx={{
                display: { mobile: "none", laptop: "block" },
                flexDirection: { mobile: "column", tablet: "column" },
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                border: "0px solid orange",
                marginTop: { laptop: "0%", desktop: "0%", largeDesktop: "0%" },
              }}
            >
              <IconButton onClick={handleArrowClick3}>
                <KeyboardArrowDownIcon
                  sx={{
                    fontSize: "5rem",
                    pointerEvents: "auto",
                    color: "#3A3A3A",
                  }}
                />
              </IconButton>
            </Box>
          </Box>

          {/* layer 10 */}
          <Box ref={ref3} sx={sxCommonPage}>
            <Box sx={{ position: "relative" }}>
              <Social />
            </Box>
          </Box>

          {/* layer 8 */}
          <Box
            sx={{
              position: "relative",
              marginTop: "20px",
              display: { mobile: "flex", tablet: "flex", laptop: "flex" },
              alignItems: "center",
              color: "white",
              background: theme === darkTheme ? "black" : "white",
              height: "45px",
              alignContent: "center",
              border: "0px solid green",
              zIndex: 10,
            }}
          >
            <Marquee style={{ color: theme === darkTheme ? "white" : "black" }}>
              <b>
                STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP
                THE BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE!
              </b>
            </Marquee>
          </Box>

          {/* layer 10b */}
          <Box sx={sxCommonPage}>
            <Box
              sx={{
                display: { mobile: "none", laptop: "block" },
                flexDirection: { mobile: "column", tablet: "column" },
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                border: "0px solid orange",
                marginTop: { laptop: "0%", desktop: "0%", largeDesktop: "0%" },
              }}
            >
              <IconButton onClick={handleArrowClick4}>
                <KeyboardArrowDownIcon
                  sx={{
                    fontSize: "5rem",
                    pointerEvents: "auto",
                    color: "#3A3A3A",
                  }}
                />
              </IconButton>
            </Box>
          </Box>

          {/* layer 11 */}
          <Box ref={ref4} sx={sxCommonPage}>
            <Box sx={{ position: "relative" }}>
              <Roadmap />
            </Box>
          </Box>

          <Paper
            sx={{
              width: "100%",
              position: "relative",
              bottom: 0,
              border: "0px solid red",
              marginTop: "250px",
            }}
            component="footer"
            square
            variant="outlined"
          >
            <Footer />
          </Paper>

          <Box
            sx={{
              "& > :not(style)": { m: 1 },
              display: { mobile: "block" },
              position: "fixed",
              bottom: 16,
              right: 16,
              zIndex: 999,
            }}
          >
            <Fab variant="extended">
              <Link
                style={{ color: "inherit", textDecoration: "inherit" }}
                to="./feed"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <ChatIcon sx={{ mr: 1 }} />
                  <Typography>
                    <b>Feed</b>
                  </Typography>
                </Box>
              </Link>
            </Fab>
          </Box>
        </Box>
      </React.Fragment>
    );
  } else {
    return <Loading />;
  }
};
