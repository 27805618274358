import * as React from "react";
import { Box, Button, ButtonProps, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useGlobalThemeContext } from "./ThemeContext";

export const Tired = () => {
  const { theme, setTheme } = useGlobalThemeContext();

  return (
    <React.Fragment>
      <Box sx={{ pointerEvents: "auto" }}>
        <Box
          sx={{
            flexDirection: { mobile: "column", tablet: "row" },
            justifyContent: "center",
            alignItems: "left",
            textAlign: "left",
            width: { mobile: "100%", laptop: "70%" },
            marginBottom: "20px",
          }}
        >
          <Typography
            sx={{
              color: "white",
              fontFamily: "Monospace",
              textShadow: "2px 2px 4px #BC1A1A 30%",
              fontSize: {
                mobile: "4rem",
                tablet: "6rem",
                laptop: "5rem",
                desktop: "7rem",
                largeDesktop: "10rem",
              },
              lineHeight: {
                mobile: "3rem",
                tablet: "5rem",
                laptop: "4rem",
                desktop: "6rem",
                largeDesktop: "8rem",
              },
              wordSpacing: ".05rem",
              wordWrap: "break-word",
            }}
          >
            <b>TIRED OF CORPORATE LIFE?</b>
          </Typography>
          <Typography
            sx={{
              fontFamily: "MedievalSharp",
              color: theme.palette.primary.main,
              fontSize: {
                mobile: "1.7rem",
                tablet: "2rem",
                laptop: "1.5rem",
                desktop: "3rem",
              },
            }}
          >
            <b>You are not alone...</b>
          </Typography>
        </Box>
        <Box
          sx={{
            alignItems: "left",
            textAlign: "left",
            paddingBottom: "10px",
            pointerEvents: "auto",
          }}
        >
          <Link to="/feed" style={{ color: "inherit", textDecoration: "none" }}>
            <Button size="large" variant="contained">
              Launch App
            </Button>
          </Link>
        </Box>
      </Box>
    </React.Fragment>
  );
};
