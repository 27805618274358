import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import cpo from "../../data/cpo.png"
import { darkTheme, sxCommonWallet } from "../customFormat";
import Marquee from 'react-fast-marquee';
import { Footer } from '../home/Footer';
import { useGlobalThemeContext } from '../home/ThemeContext';

export const Error404 = () => {

  const { theme } = useGlobalThemeContext()

  return ( 
    <React.Fragment>

      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>

        <Box sx={{ display: 'flex', flexDirection: {mobile: "column", laptop: "row", desktop: 'row'}, justifyContent: "center", 
        marginLeft: { mobile: '1%', laptop: '1%', desktop: '10%' }, marginRight: { mobile: '1%', laptop: '1%', desktop: '15%' }, marginTop: "10%"}}>
          
          <Box sx={sxCommonWallet}>
            <Box sx={{ display: 'flex', alignItems: 'center', color: 'inherit', background: theme === darkTheme ? theme.palette.background: 'white', height: "45px", alignContent: 'center', border: "0px solid green", zIndex: 10, marginBottom: '25px' }}>
              <Marquee style={{color: theme === darkTheme ? 'inherit': theme.palette.background}} pauseOnHover>
                <b>4  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  40</b>   
              </Marquee>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '25px'}}>
              <img alt="cpo" src={cpo} width="25%" style={{marginRight: "35px"}}/>
              <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                <Typography sx={{fontFamily: "MedievalSharp", fontSize: "1.2rem"}}> 
                  <b>This is a 404 error!</b>
                </Typography>
                <Typography sx={{fontFamily: "Monospace", fontSize: "1.5rem"}}>
                  "Not that I don't want to work, but looks I could not find what you are looking for..."
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', color: 'inherit', background: theme === darkTheme ? theme.palette.background: 'white', height: "45px", alignContent: 'center', border: "0px solid green", zIndex: 10, marginBottom: '25px' }}>
              <Marquee style={{color: theme === darkTheme ? 'inherit': theme.palette.background}} pauseOnHover direction="right">
                <b>  4  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  404  40</b>   
              </Marquee>
            </Box>
          
          <Link to="/" style={{ color: 'inherit', textDecoration: 'none' }}>
              <Button startIcon={<ArrowBackIcon/>}>
                  <b>Back to Home</b>
              </Button>
          </Link>

          </Box>

        </Box>

      </Box>

      <Footer/>
      
    </React.Fragment>
  )

}