import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";

export const NotConnectedChip = (user: any) => {
  return (
    <React.Fragment>
      <Paper sx={{ marginBottom: "50px" }}>
        <Box sx={{ padding: "15px", boxSizing: "border-box", gridGap: "15px" }}>
          <Typography
            sx={{
              fontFamily: "MedievalSharp",
              fontSize: { mobile: "1.2rem", laptop: "1.2rem" },
              marginBottom: { laptop: "5px", desktop: "20px" },
            }}
            gutterBottom
          >
            <b>The Peasant You Are</b>
          </Typography>
          <Typography sx={{ fontSize: { mobile: "0.8rem", laptop: "0.8rem" } }}>
            You are not connected, which means you cannot interact with our
            DApp...
          </Typography>
        </Box>
      </Paper>
    </React.Fragment>
  );
};
