import * as React from "react";
import { Box, CircularProgress } from "@mui/material";
import { sxCommonWallet } from "../customFormat";

export const Loading = () => {
  return (
    <React.Fragment>
      <Box
        sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { mobile: "column", laptop: "row", desktop: "row" },
            justifyContent: "center",
            marginLeft: { mobile: "1%", laptop: "1%", desktop: "10%" },
            marginRight: { mobile: "1%", laptop: "1%", desktop: "15%" },
            marginTop: "10%",
          }}
        >
          <Box sx={sxCommonWallet}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};
