import React, { useRef, useState, useEffect } from "react";
import { Header } from "../components/Header";
import { darkTheme, lightTheme } from "../components/customFormat";
import { MobileHeader } from "../components/MobileHeader";
import { Box } from "@mui/material";
import "@rainbow-me/rainbowkit/styles.css";
import {
  RainbowKitProvider,
  darkTheme as rDarkTheme,
  midnightTheme,
  lightTheme as rLightTheme,
  RainbowKitAuthenticationProvider,
  DisclaimerComponent,
} from "@rainbow-me/rainbowkit";
import { configureChains } from "wagmi";
import { mainnet, base, baseSepolia } from "wagmi/chains";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { createAuthenticationAdapter } from "@rainbow-me/rainbowkit";
import { SiweMessage, generateNonce } from "siwe";
import axios from "axios";
import { useAccount } from "wagmi";
import { useGlobalThemeContext } from "../components/home/ThemeContext";
import { useGlobalUserContext } from "./UserContext";
import { APIUrl } from "../components/tools/APISource";

export const Rainbow = () => {
  const { address, isConnected } = useAccount();
  const [width, setWidth] = useState(window.innerWidth);
  const [renderHeader, setRenderHeader] = useState<any>(undefined);
  const [authStatus, setAuthStatus] = useState<any>(
    address ? "authenticated" : "unauthenticated"
  );
  const { theme, setTheme } = useGlobalThemeContext();
  const { user, setUser } = useGlobalUserContext();

  const windowSize = useRef<any[]>([window.innerWidth, window.innerHeight]);
  console.log("new size", width, renderHeader);
  const { chains, publicClient } = configureChains(
    [mainnet, base, baseSepolia],
    [
      alchemyProvider({ apiKey: "xAdfrLYfHhSqirtzhRKwuT_7GZOf8gBO" }),
      publicProvider(),
    ]
  );

  // _________________________________________________________________________________________
  // ---------------------------------------- DISCONNECT -------------------------------------
  // _________________________________________________________________________________________

  // disconnect anon
  const disconnect = async () => {
    try {
      const response = await axios.request({
        url: `${APIUrl}/disconnect`,
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      if (response.status === 200) {
        console.log("user disconnected");
        return true;
      } else {
        console.log("error disconnecting");
        return false;
      }
    } catch (error: any) {
      console.log("error disconnecting");
      return false;
    }
  };

  const handleDisconnect = async () => {
    let isSuccess = await disconnect();
    if (isSuccess) {
      let anonUser = {
        address: "0x0000000000000000000000000000000000000000",
        isConnected: false,
        isAnonymous: true,
        isWeb2: false,
        isWeb3: false,
        score: 0,
      };
      setUser(anonUser);
    }
  };

  const authenticationAdapter = createAuthenticationAdapter({
    getNonce: async () => {
      return generateNonce();
    },

    createMessage: ({ nonce, address, chainId }) => {
      setAuthStatus("loading");
      return new SiweMessage({
        domain: window.location.host,
        address,
        statement:
          "Sign in with your Ethereum wallet to the app. This won't cost you any gas or fees, it's just a check!",
        uri: window.location.origin,
        version: "1",
        chainId,
        nonce,
      });
    },

    getMessageBody: ({ message }) => {
      return message.prepareMessage();
    },

    verify: async ({ message, signature }) => {
      const response = await axios.request({
        url: `${APIUrl}`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ message, signature }),
        withCredentials: true,
      });
      if (response.status === 200) {
        setAuthStatus("authenticated");
        return Boolean(true);
      } else {
        setAuthStatus("unauthenticated");
        return Boolean(false);
      }
    },

    signOut: async () => {
      handleDisconnect();
      setAuthStatus("unauthenticated");
    },
  });

  const handleWindowSize = () => {
    if (width < 640) {
      // mobile
      setRenderHeader(<MobileHeader />);
    } else if (width < 1024) {
      // tablet
      setRenderHeader(<MobileHeader />);
    } else if (width < 1440) {
      // laptop
      setRenderHeader(<Header />);
    } else if (width < 1800) {
      // desktop
      setRenderHeader(<Header />);
    } else {
      // large desktop
      setRenderHeader(<Header />);
    }
  };

  const Disclaimer: DisclaimerComponent = ({ Text, Link }) => (
    <Text>
      By connecting your wallet, you agree to the{" "}
      <Link href="https://docs.boringday.xyz/other-informations/terms">
        Terms of Service
      </Link>{" "}
      and acknowledge you have read and understand the protocol{" "}
      <Link href="https://docs.boringday.xyz/other-informations/disclaimer">
        Disclaimer
      </Link>
    </Text>
  );

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("handling");
    handleWindowSize();
  }, [width]);

  return (
    <RainbowKitAuthenticationProvider
      adapter={authenticationAdapter}
      status={authStatus}
    >
      <RainbowKitProvider
        coolMode
        appInfo={{
          appName: "A Boring Day",
          disclaimer: Disclaimer,
        }}
        theme={
          theme === darkTheme
            ? midnightTheme({
                fontStack: "system",
                accentColor: "white",
                accentColorForeground: "black",
                borderRadius: "large",
              })
            : rLightTheme()
        }
        locale="en"
        chains={chains}
      >
        {renderHeader}
      </RainbowKitProvider>
    </RainbowKitAuthenticationProvider>
  );
};
