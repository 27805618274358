import { createContext, useContext } from "react"

export type GlobalUser = {
    user: any
    setUser: (c: any) => void
}

export const GlobalUserContext = createContext<GlobalUser>({
    user: {},
    setUser: () => {},
})

export const useGlobalUserContext = () => useContext(GlobalUserContext)