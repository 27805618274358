import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Avatar, Button, Card, Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import { useGlobalUserContext } from "../../crypto/UserContext";
import { APIUrl } from "../tools/APISource";

export const FeedPostComment = ({ comment }: any) => {
  const { user, setUser } = useGlobalUserContext();
  const [peasantColor, setPeasantColor] = useState<string>("");
  const [peasantImage, setPeasantImage] = useState<any>();
  const [showComment, setShowComment] = useState<boolean>(true);

  const postRemoveComment = async (comment_id: any) => {
    const response = await axios.request({
      url: `${APIUrl}/remove-comment`,
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: JSON.stringify({ comment_id }),
      withCredentials: true,
    });
    if (response.status === 200) {
      console.log("response", response);
      return;
    } else {
      return;
    }
  };

  // remove comment
  const handleRemoveComment = () => {
    if (user.isConnected) {
      const status = postRemoveComment(comment.id);
      setShowComment(false);
      console.log("Comment deleted!", status);
    } else {
      console.log("You should connect first");
    }
  };

  const postDateConverted = (dateToConvert: any) => {
    let nowDt: any = Date.now();
    let postDt: any = new Date(dateToConvert);
    let dayDelta = (nowDt - postDt) / (24 * 60 * 60 * 1000);
    if (dayDelta < 1 / (24 * 60)) {
      return "Just now";
    } else if (dayDelta < 1 / 24) {
      return Math.round(dayDelta * 24 * 60)
        .toString()
        .concat("m");
    } else if (dayDelta < 1) {
      return Math.round(dayDelta * 24)
        .toString()
        .concat("h");
    } else if (dayDelta < 31) {
      return Math.round(dayDelta).toString().concat("d");
    } else if (dayDelta < 365) {
      return Math.round(dayDelta / 12)
        .toString()
        .concat("mo");
    } else {
      return Math.round(dayDelta / 365)
        .toString()
        .concat("y");
    }
  };

  return (
    <>
      {showComment ? (
        <Box sx={{ display: "flex", flexDirection: "row", gridGap: "10px" }}>
          <Avatar
            sx={{ width: 20, height: 20, border: `2px solid ${peasantColor}` }}
            src={peasantImage}
          />
          <Box
            sx={{ display: "flex", flexDirection: "column", gridGap: "0px" }}
          >
            <Card
              variant="outlined"
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "left",
                gap: "2px",
                padding: "8px",
                boxSizing: "border-box",
                marginBottom: "2px",
              }}
            >
              <Link
                to={`../user/${comment.user_id}`}
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                <Typography sx={{ fontSize: "0.7rem", color: "#A5A5A5" }}>
                  {comment.user_id.slice(0, 7) +
                    "..." +
                    comment.user_id.substr(comment.user_id.length - 7)}
                </Typography>
              </Link>
              <Typography sx={{ fontSize: "0.9rem" }}>
                {comment.content}
              </Typography>
            </Card>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "0.7rem", color: "#A5A5A5" }}>
                {postDateConverted(comment.created_at)}
              </Typography>
              {user.address === comment.user_id ? (
                <Button
                  size="small"
                  variant="text"
                  sx={{ textTransform: "none" }}
                  onClick={handleRemoveComment}
                >
                  <Typography sx={{ fontSize: "0.7rem" }}>delete</Typography>
                </Button>
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <Divider />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};
