import * as React from "react";
import { Box, Card, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import wtfAvatar from "../../data/wtf_circle.png";
import { SocialBubbles } from "./SocialBubbles";
import { useGlobalThemeContext } from "./ThemeContext";
import Marquee from "react-fast-marquee";
import { darkTheme, sxCommonFooter } from "../customFormat";

export const Footer = () => {
  const { theme, setTheme } = useGlobalThemeContext();

  return (
    <React.Fragment>
      <Box sx={{ bottom: 0, marginTop: "auto" }}>
        <Card
          sx={{
            width: "100%",
            position: { laptop: "relative", desktop: "relative" },
            bottom: 0,
            border: "0px solid red",
            marginTop: "auto",
          }}
          component="footer"
          square
          variant="outlined"
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "inherit",
              background:
                theme === darkTheme ? theme.palette.background : "white",
              height: "45px",
              alignContent: "center",
              border: "0px solid green",
              zIndex: 10,
            }}
          >
            <Marquee
              style={{
                color:
                  theme === darkTheme ? "inherit" : theme.palette.background,
              }}
            >
              <b>
                STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP
                THE BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE! STOP THE BORE! STOP THE
                BORE! STOP THE BORE! STOP THE BORE!
              </b>
            </Marquee>
          </Box>
          <Box sx={sxCommonFooter}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { mobile: "column", tablet: "column" },
                  justifyContent: { mobile: "center", tablet: "center" },
                  alignItems: "center",
                  paddingTop: "15px",
                  boxSizing: "border-box",
                  gridGap: "20px",
                  marginBottom: "15px",
                }}
              >
                <Box
                  sx={{
                    border: "0px solid yellow",
                    width: { mobile: "100%", tablet: "100%", desktop: "100%" },
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: { mobile: "center", tablet: "center" },
                    alignItems: "center",
                  }}
                >
                  <img
                    src={wtfAvatar}
                    width="30px"
                    style={{ marginRight: "15px" }}
                  />
                  <Typography sx={{ fontFamily: "MedievalSharp" }}>
                    A Boring Day
                  </Typography>
                </Box>
                <Box
                  sx={{
                    border: "0px solid yellow",
                    width: { mobile: "100%", tablet: "100%", desktop: "100%" },
                    display: "flex",
                    justifyContent: { mobile: "center", tablet: "center" },
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      maxWidth: { mobile: "100%", tablet: "80%" },
                      fontSize: {
                        mobile: "0.7rem",
                        tablet: "0.7rem",
                        desktop: "0.7rem",
                      },
                    }}
                  >
                    Copyright © {new Date().getFullYear()} A Boring Day. All
                    rights reserved.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    border: "0px solid yellow",
                    width: { mobile: "100%", tablet: "100%", desktop: "100%" },
                    display: "flex",
                    justifyContent: { mobile: "center", tablet: "center" },
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <SocialBubbles />
                </Box>
              </Box>
              <Box
                sx={{
                  border: "0px green solid",
                  display: "flex",
                  flexDirection: { mobile: "row", tablet: "row" },
                  justifyContent: "space-between",
                  alignItems: "baseline",
                  paddingBottom: "15px",
                  boxSizing: "border-box",
                }}
              >
                <Box
                  sx={{
                    width: { mobile: "50%", desktop: "60%" },
                    padding: "15px",
                    boxSizing: "border-box",
                    alignItems: "baseline",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        mobile: "0.7rem",
                        tablet: "0.7rem",
                        desktop: "1rem",
                      },
                    }}
                  >
                    <i>A Boring Day</i> is the first corporate meme coin on{" "}
                    <i>Base</i>. Our mission is pretty simple: give you some
                    hope that one day you will be able to retire from the
                    corporate life.
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: { mobile: "50%", desktop: "40%" },
                    border: "1px green yellow",
                    display: "flex",
                    flexDirection: { mobile: "row", tablet: "row" },
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    padding: "15px",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { mobile: "column", tablet: "column" },
                    }}
                  >
                    <Typography sx={{ fontFamily: "MedievalSharp" }}>
                      <b>Legal</b>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          mobile: "0.8rem",
                          tablet: "0.7rem",
                          desktop: "0.7rem",
                        },
                      }}
                    >
                      <Link
                        to={`https://docs.boringday.xyz/other-informations/disclaimer`}
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        Disclaimer
                      </Link>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          mobile: "0.8rem",
                          tablet: "0.7rem",
                          desktop: "0.7rem",
                        },
                      }}
                    >
                      <Link
                        to={`https://docs.boringday.xyz/other-informations/terms-of-use`}
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        Terms
                      </Link>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          mobile: "0.8rem",
                          tablet: "0.7rem",
                          desktop: "0.7rem",
                        },
                      }}
                    >
                      <Link
                        to={`https://docs.boringday.xyz/other-informations/privacy-notice`}
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        Privacy
                      </Link>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          mobile: "0.8rem",
                          tablet: "0.7rem",
                          desktop: "0.7rem",
                        },
                      }}
                    >
                      <Link
                        to={`https://docs.boringday.xyz/other-informations/risk-notice`}
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        Risk Disclosure
                      </Link>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          mobile: "0.8rem",
                          tablet: "0.7rem",
                          desktop: "0.7rem",
                        },
                      }}
                    >
                      <Link
                        to={`https://docs.boringday.xyz/other-informations/cookies-policy`}
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        Cookies Policy
                      </Link>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography sx={{ fontFamily: "MedievalSharp" }}>
                      <b>Links</b>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          mobile: "0.8rem",
                          tablet: "0.7rem",
                          desktop: "0.7rem",
                        },
                      }}
                    >
                      <Link
                        to={`../`}
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        Home
                      </Link>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          mobile: "0.8rem",
                          tablet: "0.7rem",
                          desktop: "0.7rem",
                        },
                      }}
                    >
                      <Link
                        to={`../feed`}
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        Feed
                      </Link>
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: {
                          mobile: "0.8rem",
                          tablet: "0.7rem",
                          desktop: "0.7rem",
                        },
                      }}
                    >
                      <Link
                        to={`https://docs.boringday.xyz/`}
                        style={{ color: "inherit", textDecoration: "inherit" }}
                      >
                        Docs
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Card>
      </Box>
    </React.Fragment>
  );
};
