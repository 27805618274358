import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { sxCommonUser } from "../customFormat";
import { useParams } from "react-router-dom";
import {
  Card,
  Chip,
  Skeleton,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import { FeedPost } from "../feed/FeedPost";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { BackToFeed } from "../tools/BackToFeed";
import { Footer } from "../home/Footer";
import axios from "axios";
import { loadingPost, IPost } from "../../data/posts";
import InfiniteScroll from "react-infinite-scroll-component";
import { useGlobalUserContext } from "../../crypto/UserContext";
import { APIUrl } from "../tools/APISource";

export const User = () => {
  let { id } = useParams();
  const { user, setUser } = useGlobalUserContext();
  const [score, setScore] = useState(0);
  const [userPostsScore, setUserPostsScore] = useState(0);
  const [joinDate, setJoinDate] = useState<Date>(new Date());
  const [posts, setPosts] = useState<IPost[]>(loadingPost);
  const [loading, setLoading] = useState<boolean>(false);
  const [pLimit, setPLimit] = useState<number>(10);
  const [pSkip, setPSkip] = useState<number>(0);
  const [pInitialLimit, setPInitialLimit] = useState<number>(10);
  const [pInitialSkip, setPInitialSkip] = useState<number>(0);
  const [fetchEnd, setFetchEnd] = useState<boolean>(false);
  const [statusID, setStatusID] = useState<number>(200);

  const getUserPosts = async (
    limit: any,
    skip: any,
    category: string,
    isAsc: boolean
  ) => {
    try {
      const response = await axios.get(`${APIUrl}/posts-from-poster`, {
        params: {
          limit: limit,
          skip: skip,
          category: category,
          is_asc: isAsc,
          user_address: user.address
            ? user.address
            : "0x0000000000000000000000000000000000000000",
          poster_address: id,
        },
        withCredentials: true,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        console.log("posts for user success");
        return response;
      } else {
        console.log("posts for user error");
        return { data: null, status: 200 };
      }
    } catch (error: any) {
      console.log("posts for user error");
      return error.response;
    }
  };

  const fetchMore = async () => {
    if (!fetchEnd) {
      const response = await getUserPosts(pLimit, pSkip, "all", true);
      setStatusID(response.status);
      if (response.data) {
        console.log("data len =", response.data.length);
        setPSkip(pSkip + pLimit);
        if (response.data.length < pLimit) {
          setFetchEnd(true);
        }
        setLoading(false);
        return setPosts((posts) => [...posts, ...response.data]);
      } else {
        setLoading(false);
        setFetchEnd(true);
        return;
      }
    }
    setLoading(false);
  };

  const getUser = async () => {
    const response = await axios.get(`${APIUrl}/user/${id}`);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log("no data returned");
      return null;
    }
  };

  const initialFetch = async () => {
    const postsResponse = await getUserPosts(
      pInitialLimit,
      pInitialSkip,
      "all",
      true
    );
    const user_data = await getUser();
    setPSkip(pLimit);
    if (postsResponse.data) {
      setFetchEnd(false);
      setLoading(false);
      setPosts(postsResponse.data);
      if (postsResponse.data.length === 0) {
        setFetchEnd(true);
      }
    } else {
      setLoading(false);
      setFetchEnd(true);
      setPosts(loadingPost);
    }
    if (user_data) {
      setUser({
        ...user,
        score: user_data.score,
        blacklisted: user_data.blacklisted,
        created_at: new Date(user_data.created_at),
      });
    }
    return;
  };

  useEffect(() => {
    initialFetch();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!loading) {
    return (
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            flexDirection: { mobile: "row", laptop: "row", desktop: "row" },
            justifyContent: "center",
            marginLeft: { mobile: "1%", laptop: "1%", desktop: "10%" },
            marginRight: { mobile: "1%", laptop: "1%", desktop: "10%" },
            mb: "200px",
            minHeight: "150vh",
          }}
        >
          <Box sx={sxCommonUser}>
            <Box
              sx={{
                display: "flex",
                width: { mobile: "100%", laptop: "600px", desktop: "650px" },
                alignItems: "left",
                flexDirection: {
                  mobile: "column",
                  laptop: "row",
                  desktop: "row",
                },
                border: "0px solid yellow",
              }}
            >
              <BackToFeed />
            </Box>
            <Card
              id="postCard"
              variant="outlined"
              sx={{
                padding: "15px",
                boxSizing: "border-box",
                width: { mobile: "100%", laptop: "600px", desktop: "650px" },
                maxWidth: { mobile: "100%", laptop: "600px", desktop: "650px" },
                marginBottom: "15px",
              }}
            >
              <Box sx={{ marginBottom: "15px", display: "flex" }}>
                <Typography
                  sx={{
                    fontFamily: "MedievalSharp",
                    fontSize: { mobile: "0.8rem", laptop: "1.1rem" },
                  }}
                  gutterBottom
                >
                  <b>{id}</b>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gridGap: "7px",
                  color: "#A5A5A5",
                  marginBottom: "15px",
                }}
              >
                <CalendarMonthIcon fontSize="small" />
                <Typography
                  sx={{ fontSize: { mobile: "1rem", laptop: "1rem" } }}
                  gutterBottom
                >
                  Joined
                </Typography>
                <Typography
                  sx={{ fontSize: { mobile: "1rem", laptop: "1rem" } }}
                  gutterBottom
                >
                  {joinDate.toLocaleString("en", { month: "long" }) +
                    " " +
                    joinDate.getUTCFullYear()}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: { mobile: "0.8rem", laptop: "0.8rem" },
                    marginRight: "10px",
                  }}
                  gutterBottom
                >
                  Activity
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 0.5,
                  marginBottom: "10px",
                }}
              >
                <Chip
                  sx={{ fontSize: { laptop: "0.7rem", desktop: "0.8rem" } }}
                  size="small"
                  label={userPostsScore + " posts"}
                  color="default"
                />
                <Chip
                  sx={{ fontSize: { laptop: "0.7rem", desktop: "0.8rem" } }}
                  size="small"
                  label={score + " score"}
                  color="default"
                />
              </Box>
            </Card>
            <Box
              sx={{
                display: { mobile: "block", laptop: "none" },
                width: "100%",
                marginBottom: "25px",
                border: "0px solid yellow",
              }}
            >
              <Divider>
                <Chip
                  label={
                    <Typography sx={{ fontFamily: "MedievalSharp" }}>
                      Posts
                    </Typography>
                  }
                  size="small"
                />
              </Divider>
            </Box>
            <Box
              sx={{
                width: { mobile: "100%", laptop: "600px", desktop: "650px" },
              }}
            >
              {posts.length === 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    fontFamily: "MedievalSharp",
                  }}
                >
                  No post to show
                </Box>
              ) : (
                <InfiniteScroll
                  dataLength={posts.length}
                  next={fetchMore}
                  hasMore={!fetchEnd}
                  style={{ overflowY: "hidden" }}
                  loader={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  }
                >
                  {posts.map((feedPost: any, index: any) => {
                    return <FeedPost key={index} post={feedPost} take={999} />;
                  })}
                </InfiniteScroll>
              )}
            </Box>
          </Box>
        </Box>
        <Footer />
      </React.Fragment>
    );
  } else if (loading) {
    <Skeleton variant="rectangular" width={"99%"} height={"100%"} />;
  }
};
