import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Chip,
  CircularProgress,
} from "@mui/material";
import { sxCommonWallet } from "../customFormat";
import { Balance } from "./Balance";
import { useAccount } from "wagmi";
import { UserChip } from "../feed/UserChip";
import { FeedPost } from "../feed/FeedPost";
import { Footer } from "../home/Footer";
import { loadingPost, IPost, IUser, loadingUser } from "../../data/posts";
import axios from "axios";
import InfiniteScroll from "react-infinite-scroll-component";
import { NotConnectedChip } from "../feed/NotConnectedChip";
import { useGlobalUserContext } from "../../crypto/UserContext";
import { APIUrl } from "../tools/APISource";

export const Wallet = () => {
  const { user, setUser } = useGlobalUserContext();
  const [posts, setPosts] = useState<IPost[]>(loadingPost);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [pLimit] = useState<number>(10);
  const [pSkip, setPSkip] = useState<number>(0);
  const [pInitialLimit] = useState<number>(10);
  const [pInitialSkip] = useState<number>(0);
  const [fetchEnd, setFetchEnd] = useState<Boolean>(false);
  // const [ user, setUser ] = useState<IUser>(loadingUser)

  const getUserPosts = async (
    limit: any,
    skip: any,
    category: string,
    isAsc: Boolean
  ) => {
    const response = await axios.get(`${APIUrl}/posts-from-poster`, {
      params: {
        limit: limit,
        skip: skip,
        category: category,
        is_asc: isAsc,
        user_address: user.address
          ? user.address
          : "0x0000000000000000000000000000000000000000",
        poster_address: user.address,
      },
      withCredentials: true,
    });
    if (response.status === 200) {
      console.log("fetch data", response.data.length);
      return response.data;
    } else {
      console.log("no data returned");
      return null;
    }
  };

  const fetchMore = async () => {
    if (!fetchEnd) {
      const data = await getUserPosts(pLimit, pSkip, "all", true);
      if (data) {
        console.log("data len =", data.length);
        setPSkip(pSkip + pLimit);
        if (data.length < pLimit) {
          setFetchEnd(true);
        }
        setLoading(false);
        return setPosts((posts) => [...posts, ...data]);
      } else {
        setLoading(false);
        setFetchEnd(true);
        return;
      }
    }
    setLoading(false);
  };

  const getUser = async () => {
    const response = await axios.get(`${APIUrl}/user/${user.address}`);
    if (response.status === 200) {
      console.log("fetch data", response.data.length);
      return response.data;
    } else {
      console.log("no data returned");
      return null;
    }
  };

  const initialFetch = async () => {
    if (user.isConnected) {
      const posts_data = await getUserPosts(
        pInitialLimit,
        pInitialSkip,
        "all",
        true
      );
      const user_data = await getUser();
      setPSkip(pLimit);
      if (posts_data) {
        setFetchEnd(false);
        setLoading(false);
        setPosts(posts_data);
      } else {
        setLoading(false);
        setFetchEnd(true);
        setPosts(loadingPost);
      }
      if (user_data) {
        console.log("user data", user_data);
        setUser({
          ...user,
          score: user_data.score,
          blacklisted: user_data.blacklisted,
          created_at: new Date(user_data.created_at),
        });
      }
    }
    return;
  };

  useEffect(() => {
    initialFetch();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { mobile: "column", laptop: "row", desktop: "row" },
          justifyContent: "center",
          marginLeft: { mobile: "1%", laptop: "1%", desktop: "10%" },
          marginRight: { mobile: "1%", laptop: "1%", desktop: "15%" },
        }}
      >
        <Box sx={sxCommonWallet}>
          <Box>Loading...</Box>
        </Box>
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: { mobile: "row", laptop: "row", desktop: "row" },
          justifyContent: "center",
          alignItems: "top",
          marginLeft: { mobile: "1%", laptop: "1%", desktop: "10%" },
          marginRight: { mobile: "1%", laptop: "1%", desktop: "15%" },
          minHeight: "150vh",
        }}
      >
        <Box sx={sxCommonWallet}>
          <Box
            sx={{
              display: { mobile: "block", laptop: "block" },
              width: "100%",
              marginBottom: "25px",
              border: "0px solid yellow",
            }}
          >
            <Divider>
              <Chip
                label={
                  <Typography sx={{ fontFamily: "MedievalSharp" }}>
                    Wallet
                  </Typography>
                }
                size="small"
              />
            </Divider>
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { mobile: "column", tablet: "row" },
              gridGap: { mobile: "0px", laptop: "20px" },
              justifyContent: "center",
            }}
          >
            <Balance user={user} />
            {user.isConnected ? <UserChip user={user} /> : <NotConnectedChip />}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { mobile: "column", tablet: "column" },
              width: "100%",
              gridGap: { mobile: "0px", laptop: "20px" },
              justifyContent: "center",
              marginBottom: "20px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: { mobile: "block", laptop: "block" },
                width: "100%",
                marginBottom: "25px",
                border: "0px solid yellow",
              }}
            >
              <Divider>
                <Chip
                  label={
                    <Typography sx={{ fontFamily: "MedievalSharp" }}>
                      Posts
                    </Typography>
                  }
                  size="small"
                />
              </Divider>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { mobile: "column", tablet: "column" },
                width: "100%",
                gridGap: { mobile: "0px", laptop: "20px" },
                alignItems: "center",
              }}
            >
              {posts.length === 0 || !user.isConnected ? (
                <Typography sx={{ fontFamily: "MedievalSharp" }}>
                  There is no post to show
                </Typography>
              ) : (
                <InfiniteScroll
                  dataLength={posts.length}
                  next={fetchMore}
                  hasMore={!fetchEnd}
                  style={{ overflowY: "hidden" }}
                  loader={
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  }
                >
                  {posts.map((feedPost: any, index: any) => {
                    return <FeedPost key={index} post={feedPost} take={999} />;
                  })}
                </InfiniteScroll>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Footer />
    </React.Fragment>
  );
};
