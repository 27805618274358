import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Typography,
  CardContent,
} from "@mui/material";
import { Link } from "react-router-dom";
import { marketingPost } from "../../data/posts";
import babel from "../../data/babel.jpg";
import { useGlobalThemeContext } from "./ThemeContext";

export const Legacy = () => {
  const [post, setPost] = useState<any>();
  const [currentPost, setCurrentPost] = useState<any>(0);
  const { theme, setTheme } = useGlobalThemeContext();

  useEffect(() => {
    if (marketingPost !== undefined) {
      setPost(marketingPost[currentPost]);
    } else {
      setPost(undefined);
    }
  }, []);

  return (
    <React.Fragment>
      <Box
        sx={{
          flexDirection: { mobile: "column", laptop: "row-reverse" },
          display: "flex",
          border: "0px solid yellow",
          pointerEvent: "auto",
        }}
      >
        <Card
          variant="outlined"
          sx={{
            flexDirection: {
              mobile: "column",
              tablet: "column",
              laptop: "column",
            },
            display: "flex",
            alignItems: "center",
            gridGap: "25px",
            padding: "15px",
            boxSizing: "border-box",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                mobile: "column",
                tablet: "row",
                laptop: "column",
              },
              width: { mobile: "100%", laptop: "100%" },
            }}
          >
            <Box
              sx={{
                flexDirection: { mobile: "row", tablet: "row" },
                width: { mobile: "100%", laptop: "100%" },
                marginBottom: "10px",
              }}
            >
              <CardHeader
                subheader={
                  <Typography
                    sx={{ color: "#A5A5A5", fontFamily: "Monospace" }}
                  >
                    <b>The end of the coporate duty</b>
                  </Typography>
                }
                title={
                  <Typography
                    sx={{
                      fontFamily: "MedievalSharp",
                      fontSize: {
                        mobile: "1.2rem",
                        laptop: "1.3rem",
                        desktop: "2rem",
                      },
                    }}
                  >
                    <b>The legacy of Not Pieter Brueghel</b>
                  </Typography>
                }
              />
              <CardContent
                sx={{
                  flexDirection: { mobile: "column", tablet: "column" },
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      mobile: "1.1rem",
                      laptop: "0.9rem",
                      desktop: "1rem",
                    },
                    marginBottom: { mobile: "15px", laptop: "20px" },
                  }}
                >
                  <i>A Boring Day</i> aims to pursue the dream of Not Pieter
                  Brueghel and be the largest un-corporate (or non-corporate, or
                  whatever you want to call it) community on the Blockchain.
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      mobile: "1.1rem",
                      laptop: "0.9rem",
                      desktop: "1rem",
                    },
                    marginBottom: { mobile: "15px", laptop: "20px" },
                  }}
                >
                  Like Pieter almost 500 years ago, millions of people still
                  stand in line everyday for another workday: hours spent on
                  transportation, endless meetings endured, and the same
                  meaningless tasks, day after day, month after month. This has
                  to stop.
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      mobile: "1.1rem",
                      laptop: "0.9rem",
                      desktop: "1rem",
                    },
                    marginBottom: { mobile: "15px", laptop: "20px" },
                  }}
                >
                  This is why <i>A Boring Day</i> was developed. As boring as
                  things can be, you are not alone. A lot of people have
                  probably experienced similar situations, and a lot of people
                  will undoubtedly be very happy to know that they are not the
                  only ones to feel that way. That is why <i>A Boring Day</i> is
                  made of a single main{" "}
                  <Link
                    style={{
                      color: theme.palette.primary.main,
                      textDecoration: "inherit",
                    }}
                    to="./feed"
                  >
                    <b>FEED</b>
                  </Link>{" "}
                  page, where anyone can share their own corporate stories and
                  interact with other people’s stories.
                </Typography>
                <Box
                  sx={{
                    fontFamily: "MedievalSharp",
                    fontSize: {
                      mobile: "1.1rem",
                      laptop: "0.9rem",
                      desktop: "1.2rem",
                    },
                    marginBottom: { mobile: "15px", laptop: "20px" },
                  }}
                >
                  <Link to={"./feed"}>
                    <Button variant="outlined">Go to Feed</Button>
                  </Link>
                </Box>
                <Typography
                  sx={{
                    fontFamily: "MedievalSharp",
                    fontSize: {
                      mobile: "1.2rem",
                      laptop: "0.9rem",
                      desktop: "1.1rem",
                    },
                  }}
                  gutterBottom
                >
                  This is our way. Speak your mind.
                </Typography>
              </CardContent>
            </Box>
          </Box>
          <Box sx={{ width: { mobile: "100%", laptop: "75%" } }}>
            <img src={babel} width="100%" style={{ borderRadius: "10px" }} />
            <Typography
              sx={{
                fontSize: {
                  mobile: "0.7rem",
                  laptop: "0.75rem",
                  desktop: "0.8rem",
                },
                textAlign: "center",
              }}
              gutterBottom
            >
              <i>The Corporate Tower</i>, Not Pieter Brueghel's first and last
              company
            </Typography>
          </Box>
        </Card>
      </Box>
    </React.Fragment>
  );
};
