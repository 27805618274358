import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import {
  Alert,
  Avatar,
  Button,
  Card,
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import DiamondIcon from "@mui/icons-material/Diamond";
import wtfAvatar from "../../data/wtf_circle.png";
import cpo from "../../data/cpo.png";
import hd from "../../data/head.png";
import manure from "../../data/manure.png";
import stable from "../../data/stable.png";
import ReportIcon from "@mui/icons-material/Report";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { TwitterShareButton } from "react-share";
import XIcon from "@mui/icons-material/X";
import { useGlobalThemeContext } from "../home/ThemeContext";
import axios from "axios";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HeartBrokenOutlinedIcon from "@mui/icons-material/HeartBrokenOutlined";
import HeartBrokenIcon from "@mui/icons-material/HeartBroken";
import CommentIcon from "@mui/icons-material/Comment";
import SendIcon from "@mui/icons-material/Send";
import { FeedPostComment } from "./FeedPostComment";
import { useGlobalUserContext } from "../../crypto/UserContext";
import { ErrorHandler } from "../tools/APIStatusHandler";
import { APIUrl } from "../tools/APISource";

let maxPostSize = 500;
let maxShowChar = 250;
let maxReport = 5;

export const FeedPost = ({ post }: any) => {
  console.log("current post", post);
  let postDate = post.created_at;

  const { user, setUser } = useGlobalUserContext();
  const { theme, setTheme } = useGlobalThemeContext();
  const [postContent, setPostContent] = useState<string>("");
  const [likeButton, setLikeButton] = useState<boolean>(false);
  const [dislikeButton, setDislikeButton] = useState<boolean>(false);
  const [showAllPost, setShowAllPost] = useState<boolean>(false);
  const [peasantName, setPeasantName] = useState<string>("");
  const [peasantColor, setPeasantColor] = useState<string>("");
  const [peasantImage, setPeasantImage] = useState<any>();
  const [postScore, setPostScore] = useState<any>(0);
  const [showPost, setShowPost] = useState<boolean>(true);
  const [showDeleteSuccessSnack, setShowDeleteSuccessSnack] =
    useState<boolean>(false);
  const [showDeleteErrorSnack, setShowDeleteErrorSnack] =
    useState<boolean>(false);
  const [showComments, setShowComments] = useState<boolean>(false);
  const valueRef = useRef<HTMLInputElement>(null);
  const [alertContent, setAlertContent] = useState<Boolean>(false);
  const [showCommentSuccess, setShowCommentSuccess] = useState(false);
  const [thisPost, setThisPost] = useState<any>(post);
  const [statusID, setStatusID] = useState<number>(200);

  console.log("this post", thisPost);

  useEffect(() => {
    setThisPost(post);
  }, [post]);

  useEffect(() => {
    if (thisPost.content.length <= maxShowChar || showAllPost) {
      setPostContent(thisPost.content);
    } else {
      setPostContent(thisPost.content.slice(0, maxShowChar).concat(" (...)"));
    }
    setLikeButton(thisPost.has_liked);
    setDislikeButton(thisPost.has_disliked);
    setPostScore(2 * thisPost.likes_count - thisPost.dislikes_count);
  }, [thisPost, user.address, showAllPost]);

  // ______________________________________________________________________________________
  // ---------------------------------------- LIKE ----------------------------------------
  // ______________________________________________________________________________________

  // post new like
  const postLike = async (post_id: any) => {
    try {
      const response = await axios.request({
        url: `${APIUrl}/add-like`,
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ post_id }),
        withCredentials: true,
      });
      if (response.status === 200) {
        console.log("like remove success");
        return true;
      } else {
        console.log("like remove error");
        return false;
      }
    } catch (error: any) {
      console.log("like remove error");
      setStatusID(error.response.status);
      return false;
    }
  };

  // remove like
  const postRemoveLike = async (post_id: any) => {
    try {
      const response = await axios.request({
        url: `${APIUrl}/remove-like`,
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ post_id }),
        withCredentials: true,
      });
      if (response.status === 200) {
        console.log("like remove success");
        return true;
      } else {
        console.log("like remove error");
        return false;
      }
    } catch (error: any) {
      console.log("like remove error");
      setStatusID(error.response.status);
      return false;
    }
  };

  // handle like and remove like based on previous interactions
  const handleLike = async () => {
    let newThisPost = thisPost;
    if (dislikeButton) {
      let isSuccess = await postRemoveDislike(thisPost.id);
      if (isSuccess) {
        newThisPost = {
          ...newThisPost,
          has_disliked: false,
          dislikes_count: newThisPost.dislikes_count - 1,
        };
        setThisPost(newThisPost);
      }
    }
    if (!likeButton) {
      let isSuccess = await postLike(thisPost.id);
      if (isSuccess) {
        newThisPost = {
          ...newThisPost,
          has_liked: true,
          likes_count: thisPost.likes_count + 1,
        };
        setThisPost(newThisPost);
      }
    } else {
      let isSuccess = await postRemoveLike(thisPost.id);
      if (isSuccess) {
        newThisPost = {
          ...newThisPost,
          has_liked: false,
          likes_count: thisPost.likes_count - 1,
        };
        setThisPost(newThisPost);
      }
    }
  };

  // _________________________________________________________________________________________
  // ---------------------------------------- DISLIKE ----------------------------------------
  // _________________________________________________________________________________________

  // post dislike

  const postDislike = async (post_id: any) => {
    try {
      const response = await axios.request({
        url: `${APIUrl}/add-dislike`,
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ post_id }),
        withCredentials: true,
      });
      if (response.status === 200) {
        console.log("dislike success");
        return true;
      } else {
        console.log("dislike error");
        return false;
      }
    } catch (error: any) {
      console.log("dislike error");
      setStatusID(error.response.status);
      return false;
    }
  };

  // remove dislike
  const postRemoveDislike = async (post_id: any) => {
    try {
      const response = await axios.request({
        url: `${APIUrl}/remove-dislike`,
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ post_id }),
        withCredentials: true,
      });
      if (response.status === 200) {
        console.log("dislike remove success");
        return true;
      } else {
        console.log("dislike remove error");
        return false;
      }
    } catch (error: any) {
      console.log("dislike remove error");
      setStatusID(error.response.status);
      return false;
    }
  };

  // handle dislike and remove dislike based on previous interactions
  const handleDislike = async () => {
    let newThisPost = thisPost;
    if (likeButton) {
      let isSuccess = await postRemoveLike(thisPost.id);
      if (isSuccess) {
        newThisPost = {
          ...newThisPost,
          has_liked: false,
          likes_count: newThisPost.likes_count - 1,
        };
        setThisPost(newThisPost);
      }
    }
    if (!dislikeButton) {
      let isSuccess = await postDislike(thisPost.id);
      if (isSuccess) {
        newThisPost = {
          ...newThisPost,
          has_disliked: true,
          dislikes_count: thisPost.dislikes_count + 1,
        };
        setThisPost(newThisPost);
      }
    } else {
      let isSuccess = await postRemoveDislike(thisPost.id);
      if (isSuccess) {
        newThisPost = {
          ...newThisPost,
          has_disliked: false,
          dislikes_count: thisPost.dislikes_count - 1,
        };
        setThisPost(newThisPost);
      }
    }
  };

  // _________________________________________________________________________________________
  // ---------------------------------------- REPORT -----------------------------------------
  // _________________________________________________________________________________________

  // post report
  const postReport = async (post_id: any) => {
    try {
      const response = await axios.request({
        url: `${APIUrl}/add-report`,
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ post_id }),
        withCredentials: true,
      });
      if (response.status === 200) {
        console.log("report success");
        return true;
      } else {
        console.log("report error");
        return false;
      }
    } catch (error: any) {
      console.log("report error");
      setStatusID(error.response.status);
      return false;
    }
  };

  // handle report (--> bad for the app)
  const handleReport = async () => {
    let isSuccess = await postReport(thisPost.id);
    if (isSuccess) {
      setShowPost(false);
      setAnchorEl(null);
    }
  };

  // _________________________________________________________________________________________
  // ---------------------------------------- POST -------------------------------------------
  // _________________________________________________________________________________________

  // remove post
  const postRemovePost = async (post_id: any) => {
    try {
      const response = await axios.request({
        url: `${APIUrl}/remove-post`,
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ post_id }),
        withCredentials: true,
      });
      if (response.status === 200) {
        console.log("post remove success");
        return true;
      } else {
        console.log("post remove error");
        return false;
      }
    } catch (error: any) {
      console.log("report error");
      setStatusID(error.response.status);
      return false;
    }
  };

  // handle remove post
  const handleRemovePost = async () => {
    let isSuccess = await postRemovePost(thisPost.id);
    if (isSuccess) {
      setShowPost(false);
      setAnchorEl(null);
    }
  };

  // _________________________________________________________________________________________
  // ---------------------------------------- COMMENT ----------------------------------------
  // _________________________________________________________________________________________

  // post comment
  const postComment = async (post_id: any, content: any) => {
    try {
      const response = await axios.request({
        url: `${APIUrl}/add-comment`,
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: JSON.stringify({ post_id, content }),
        withCredentials: true,
      });
      if (response.status === 200) {
        console.log("comment success");
        if (thisPost.comments == null) {
          setThisPost({
            ...thisPost,
            comments: [
              {
                id: response.data.message,
                user_id: user.address,
                content: content,
                created_at: Date.now(),
              },
            ],
          });
        } else {
          setThisPost({
            ...thisPost,
            comments: [
              {
                id: response.data.message,
                user_id: user.address,
                content: content,
                created_at: Date.now(),
              },
              ...thisPost.comments,
            ],
          });
        }
        return true;
      } else {
        console.log("comment error");
        return false;
      }
    } catch (error: any) {
      console.log("comment error");
      setStatusID(error.response.status);
      return false;
    }
  };

  // handle comment
  const handleComment = async () => {
    // check if comment is not null
    if (valueRef.current?.value.length === 0) {
      setAlertContent(true);
      console.log("comment error");
      return;
    }
    let isSuccess = await postComment(thisPost.id, valueRef.current?.value);
    if (isSuccess) {
      setShowCommentSuccess(true);
      if (valueRef.current !== null) {
        valueRef.current.value = "";
      }
    }
  };

  // _________________________________________________________________________________________
  // ---------------------------------------- OTHER ------------------------------------------
  // _________________________________________________________________________________________

  // compute user score
  const computeScore = () => {
    if (thisPost.score !== undefined) {
      let userLevel = Math.floor(thisPost.score / 10);
      let userAdvancement = Math.round(
        ((thisPost.score - userLevel * 10) / 10) * 100
      );
      if (userLevel < 1) {
        setPeasantName("Manure Assistant");
        setPeasantColor("#8B4513");
        setPeasantImage(manure);
      } else if (userLevel < 2) {
        setPeasantName("Stable Manager");
        setPeasantColor("#6B8E23");
        setPeasantImage(stable);
      } else if (userLevel < 3) {
        setPeasantName("Farm Director");
        setPeasantColor("#DA9100");
        setPeasantImage(wtfAvatar);
      } else if (userLevel < 10) {
        setPeasantName("Head of Harvesting");
        setPeasantColor("#FFBF00");
        setPeasantImage(hd);
      } else {
        setPeasantName("Chief Peasantry Officer");
        setPeasantColor("#FFD700");
        setPeasantImage(cpo);
      }
    }
  };

  useEffect(() => {
    computeScore();
  }, [post, thisPost]);

  const postDateConverted = (dateToConvert: any) => {
    let nowDt: any = Date.now();
    let postDt: any = dateToConvert;
    let dayDelta = (nowDt - postDt) / (24 * 60 * 60 * 1000);
    if (dayDelta < 1 / (24 * 60)) {
      return "Just now";
    } else if (dayDelta < 1 / 24) {
      return Math.round(dayDelta * 24 * 60)
        .toString()
        .concat("m");
    } else if (dayDelta < 1) {
      return Math.round(dayDelta * 24)
        .toString()
        .concat("h");
    } else if (dayDelta < 31) {
      return Math.round(dayDelta).toString().concat("d");
    } else if (dayDelta < 365) {
      return Math.round(dayDelta / 12)
        .toString()
        .concat("mo");
    } else {
      return Math.round(dayDelta / 365)
        .toString()
        .concat("y");
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSnack = () => {
    setShowDeleteSuccessSnack(false);
    setShowCommentSuccess(false);
  };

  const windowSize = useRef<any[]>([window.innerWidth, window.innerHeight]);

  const handleUserIdShow = () => {
    if (windowSize.current[0] < 640) {
      // mobile
      return (
        thisPost.address.slice(0, 7) +
        "..." +
        thisPost.address.substr(thisPost.address.length - 7)
      );
    } else if (windowSize.current[0] < 1024) {
      // tablet
      return (
        thisPost.address.slice(0, 10) +
        "..." +
        thisPost.address.substr(thisPost.address.length - 10)
      );
    } else if (windowSize.current[0] < 1440) {
      // laptop
      return thisPost.address;
    } else if (windowSize.current[0] < 1800) {
      // desktop
      return thisPost.address;
    } else {
      // large desktop
      return thisPost.address;
    }
  };

  return (
    <>
      {thisPost.reports_count <= maxReport &&
      showPost &&
      !thisPost.has_reported ? (
        <>
          <Card
            sx={{
              padding: "0px",
              boxSizing: "border-box",
              width: { mobile: "100%", laptop: "100%", desktop: "650px" },
              maxWidth: { mobile: "100%", laptop: "100%", desktop: "650px" },
              marginBottom: "15px",
              boxShadow: 3,
            }}
          >
            <Stack spacing={0}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "left",
                  gap: "10px",
                  justifyContent: "space-between",
                  padding: "10px",
                  boxSizing: "border-box",
                }}
              >
                <Chip
                  avatar={
                    <Avatar
                      sx={{
                        width: 24,
                        height: 24,
                        border: `2px solid ${peasantColor}`,
                      }}
                      src={peasantImage}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        fontSize: {
                          mobile: "0.7rem",
                          laptop: "0.7rem",
                          desktop: "0.8rem",
                        },
                        fontFamily: "MedievalSharp",
                      }}
                    >
                      {peasantName}
                    </Typography>
                  }
                  variant="outlined"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "0.7rem",
                    justifyContent: "center",
                  }}
                />
                <Link
                  to={`../user/${thisPost.address}`}
                  style={{ color: "inherit", textDecoration: "inherit" }}
                >
                  <Typography
                    sx={{
                      fontSize: {
                        mobile: "0.7rem",
                        laptop: "0.8rem",
                        desktop: "0.9rem",
                      },
                      "&:hover": { color: theme.palette.primary.main },
                    }}
                  >
                    {handleUserIdShow()}
                  </Typography>
                </Link>
                <IconButton
                  sx={{ color: "#A5A5A5" }}
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{ "aria-labelledby": "long-button" }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                  {user.address === thisPost.address ? (
                    <MenuItem key={"delete"} onClick={handleRemovePost}>
                      <DeleteIcon
                        fontSize="small"
                        sx={{ marginRight: "5px" }}
                      />
                      <Typography>Delete</Typography>
                    </MenuItem>
                  ) : (
                    <></>
                  )}
                  <MenuItem key={"report"} onClick={handleReport}>
                    <ReportIcon fontSize="small" sx={{ marginRight: "5px" }} />
                    <Typography>Report</Typography>
                  </MenuItem>
                  <MenuItem disabled key={"support"} onClick={handleClose}>
                    <DiamondIcon fontSize="small" sx={{ marginRight: "5px" }} />
                    <Typography>Support</Typography>
                  </MenuItem>
                </Menu>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  textAlign: "left",
                  gap: "10px",
                  justifyContent: "space-between",
                  padding: "10px",
                  boxSizing: "border-box",
                }}
              >
                <Typography sx={{ fontSize: "0.7rem", color: "#A5A5A5" }}>
                  {postDateConverted(postDate)}
                </Typography>
                <Typography gutterBottom sx={{ fontSize: "0.95rem" }}>
                  <Link
                    to={`../post/${thisPost.id}`}
                    style={{
                      color: theme.palette.text.primary,
                      textDecoration: "none",
                    }}
                  >
                    {postContent}
                  </Link>
                  {!showAllPost ? (
                    <>
                      {thisPost.content.length <= maxShowChar ? (
                        <></>
                      ) : (
                        <Button
                          size="small"
                          variant="text"
                          sx={{ zIndex: 9999 }}
                          onClick={() => setShowAllPost(!showAllPost)}
                        >
                          <i>show more</i>
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      {/*<Button variant='text' onClick={() => setShowAllPost(!showAllPost)}><i>show less</i></Button>*/}
                    </>
                  )}
                </Typography>

                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {thisPost.categories ? (
                    <Chip
                      size="small"
                      key={0}
                      label={
                        <Typography sx={{ fontSize: "0.7rem" }}>
                          {thisPost.categories[1]}
                        </Typography>
                      }
                      color="default"
                    />
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>

              <Divider />

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  padding: "4px",
                  boxSizing: "border-box",
                }}
              >
                {/* <Typography fontSize="small">Peasant life</Typography><Tooltip title="How much OP has a peasant life (high means high and low means low, nothing too complicated here)"><IconButton><HelpOutlineOutlinedIcon fontSize="small" /></IconButton></Tooltip> */}
                <Tooltip title="Comments!!!">
                  <Button
                    size="small"
                    variant="text"
                    color="inherit"
                    onClick={() => setShowComments(!showComments)}
                    startIcon={<CommentIcon />}
                  >
                    <Typography sx={{ fontSize: "0.9rem" }}>
                      {thisPost.comments ? thisPost.comments.length : 0}
                    </Typography>
                  </Button>
                </Tooltip>
                <Tooltip title="LFG!!!">
                  <Button
                    size="small"
                    variant={likeButton ? "contained" : "text"}
                    color={likeButton ? "primary" : "inherit"}
                    onClick={handleLike}
                    disabled={!user.isConnected}
                    startIcon={
                      likeButton ? (
                        <FavoriteIcon />
                      ) : (
                        <FavoriteBorderOutlinedIcon />
                      )
                    }
                  >
                    <Typography sx={{ fontSize: "0.9rem" }}>
                      {thisPost.likes_count}
                    </Typography>
                  </Button>
                </Tooltip>
                {/* <Chip variant='outlined' label={postScore}/> */}
                <Tooltip title="Nay...">
                  <Button
                    size="small"
                    variant={dislikeButton ? "contained" : "text"}
                    color={dislikeButton ? "primary" : "inherit"}
                    onClick={handleDislike}
                    disabled={!user.isConnected}
                    startIcon={
                      dislikeButton ? (
                        <HeartBrokenIcon />
                      ) : (
                        <HeartBrokenOutlinedIcon />
                      )
                    }
                  >
                    <Typography sx={{ fontSize: "0.9rem" }}>
                      {thisPost.dislikes_count}
                    </Typography>
                  </Button>
                </Tooltip>
                <IconButton>
                  <TwitterShareButton
                    url={`boringday.xyz/post/${thisPost.id}`}
                    title={
                      thisPost.content.slice(0, 150) +
                      "..." +
                      "\n #BoringDay #CryptoCommunity\n___\nRead more on: "
                    }
                  >
                    <XIcon fontSize="small" />
                  </TwitterShareButton>
                </IconButton>
              </Box>
              <Divider />
              {showComments ? (
                <Box>
                  <Box sx={{ display: "flex", flexDirection: "row", m: 2 }}>
                    <TextField
                      fullWidth
                      size="small"
                      inputRef={valueRef}
                      multiline={true}
                      maxRows={10}
                      id="outlined-basic"
                      inputProps={{ maxLength: maxPostSize }}
                      variant="outlined"
                      label={
                        user.isConnected ? (
                          <Typography sx={{ fontSize: "0.8rem" }}>
                            Write a comment
                          </Typography>
                        ) : (
                          <Typography sx={{ fontSize: "0.8rem" }}>
                            Please connect your wallet first
                          </Typography>
                        )
                      }
                      disabled={!user.isConnected}
                      color={user.isConnected ? "primary" : "error"}
                    />
                    <IconButton
                      color="secondary"
                      aria-label="add a comment"
                      onClick={handleComment}
                    >
                      <SendIcon />
                    </IconButton>
                  </Box>
                  <Box>
                    {thisPost.comments ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "left",
                          gap: "10px",
                          padding: "10px",
                          boxSizing: "border-box",
                        }}
                      >
                        {thisPost.comments.map((comment: any) => {
                          return <FeedPostComment comment={comment} />;
                        })}
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          m: 2,
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.8rem",
                            fontFamily: "MedievalSharp",
                          }}
                        >
                          - Be the first bored peasant to comment -
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              ) : (
                <></>
              )}
            </Stack>
          </Card>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {statusID !== 200 ? (
              <ErrorHandler returnedError={statusID} />
            ) : (
              <></>
            )}

            <Snackbar
              open={showDeleteSuccessSnack}
              autoHideDuration={5000}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              onClose={handleCloseSnack}
            >
              <Alert onClose={handleCloseSnack} severity="success">
                Post successfully deleted!
              </Alert>
            </Snackbar>
            <Snackbar
              open={showCommentSuccess}
              autoHideDuration={5000}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              onClose={handleCloseSnack}
            >
              <Alert onClose={handleCloseSnack} severity="success">
                Comment created!
              </Alert>
            </Snackbar>
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
