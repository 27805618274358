
export interface IPost {
    id: number;
    address: string;
    categories: string[];
    content: string;
    dislikes_count: number;
    likes_count: number;
    reports_count: number;
    score: number;
  }

export interface IUser {
    address: string;
    user_name: string;
    score: number;
    created_at: number;
    blacklisted: boolean
  }

export let loadingPost: IPost[] = 
    [
        {
        "id": 0,
        "address": "0x0",
        "content": "Loading...",
        "categories": ["all"],
        "likes_count": 0,
        "dislikes_count": 0,
        "reports_count": 0,
        "score": 0
    }
]

export let loadingUser: IUser = 
    {
        "address": "0x0000000000000000000000000000000000000000",
        "user_name": "0x000000000000000000000000000000000000dead",
        "score": 0,
        "created_at": 0,
        "blacklisted": false
    }

export let marketingPost: any[] =
[
    {
    "id": "ex1",
    "user": {
        "id": "0xEB...F373",
        "posts": [],
        "likes": [],
        "dislikes": [],
        "reports": []
    },
    "content": "Another day, another round of mind-numbing data entry. Sat through a meeting where we debated the ideal font for reports. Yes, you read that right...",
    "date": "just now",
    "likes": 96,
    "dislikes": 23,
    "reports": []
},
{
    "id": "ex1",
    "user": {
        "id": "0xE3...31CD",
        "posts": [],
        "likes": [],
        "dislikes": [],
        "reports": []
    },
    "content": "Today's highlight: attending a team-building seminar on mastering the art of email signatures. Seriously? Is this the key to a successful career?",
    "date": "5m ago",
    "likes": 55,
    "dislikes": 12,
    "reports": []
},
{
    "id": "ex1",
    "user": {
        "id": "0xE3...31CD",
        "posts": [],
        "likes": [],
        "dislikes": [],
        "reports": []
    },
    "content": "Today's highlight: attending a team-building seminar on mastering the art of email signatures. Seriously? Is this the key to a successful career?",
    "date": "5m ago",
    "likes": 55,
    "dislikes": 12,
    "reports": []
}
]
