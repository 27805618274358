import { createContext, useContext } from "react"
import { darkTheme, lightTheme } from "../customFormat";

export type GlobalTheme = {
    theme: any
    setTheme: (c: any) => void
}

export const GlobalThemeContext = createContext<GlobalTheme>({
    theme: darkTheme, // set a default value
    setTheme: () => {},
})

export const useGlobalThemeContext = () => useContext(GlobalThemeContext)