import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Avatar, Chip, Paper } from "@mui/material";
import wtfAvatar from "../../data/wtf_circle.png";
import cpo from "../../data/cpo.png";
import hd from "../../data/head.png";
import manure from "../../data/manure.png";
import stable from "../../data/stable.png";
import { IUser } from "../../data/posts";

export const UserChip = ({ user }: any) => {
  const [score, setScore] = useState<number>(user.score);
  const [level, setLevel] = useState<number>(Math.floor(score / 10));
  const [advancement, setAdvancement] = useState(
    Math.round(((score - level * 10) / 10) * 100)
  );
  const [peasantName, setPeasantName] = useState<string>("Manure Assistant");
  const [peasantColor, setPeasantColor] = useState<string>("#8B4513");
  const [peasantImage, setPeasantImage] = useState<any>(manure);

  const computeScore = (user: IUser) => {
    if (user.score) {
      let userLevel = Math.floor(user.score / 10);
      setScore(user.score);
      setLevel(userLevel);
      setAdvancement(Math.round(((user.score - userLevel * 10) / 10) * 100));
      console.log("level", userLevel);
      if (userLevel < 1) {
        setPeasantName("Manure Assistant");
        setPeasantColor("#8B4513");
        setPeasantImage(manure);
        setAdvancement(Math.round(((user.score - userLevel * 10) / 10) * 100));
      } else if (userLevel < 2) {
        setPeasantName("Stable Manager");
        setPeasantColor("#6B8E23");
        setPeasantImage(stable);
        setAdvancement(Math.round(((user.score - userLevel * 10) / 10) * 100));
      } else if (userLevel < 3) {
        setPeasantName("Farm Director");
        setPeasantColor("#DA9100");
        setPeasantImage(wtfAvatar);
        setAdvancement(Math.round(((user.score - userLevel * 10) / 10) * 100));
      } else if (userLevel < 10) {
        setPeasantName("Head of Harvesting");
        setPeasantColor("#FFBF00");
        setPeasantImage(hd);
        setAdvancement(Math.round(((user.score - userLevel * 10) / 70) * 100));
      } else {
        setPeasantName("Chief Peasantry Officer");
        setPeasantColor("#FFD700");
        setPeasantImage(cpo);
      }
    }
  };

  useEffect(() => {
    computeScore(user);
  }, [user]);

  useEffect(() => {
    computeScore(user);
  });

  return (
    <React.Fragment>
      <Paper sx={{ marginBottom: "50px" }}>
        <Box sx={{ padding: "15px", boxSizing: "border-box", gridGap: "15px" }}>
          <Typography
            sx={{
              fontFamily: "MedievalSharp",
              fontSize: { mobile: "1.2rem", laptop: "1.2rem" },
              marginBottom: { laptop: "5px", desktop: "20px" },
            }}
            gutterBottom
          >
            <b>The Peasant You Are</b>
          </Typography>
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
          >
            <Chip
              avatar={
                <Avatar
                  sx={{
                    width: 24,
                    height: 24,
                    border: `2px solid ${peasantColor}`,
                  }}
                  src={peasantImage}
                />
              }
              label={
                <Typography
                  sx={{
                    fontSize: {
                      mobile: "0.7rem",
                      laptop: "0.7rem",
                      desktop: "0.8rem",
                    },
                    fontFamily: "MedievalSharp",
                  }}
                >
                  {peasantName}
                </Typography>
              }
              variant="outlined"
              sx={{
                display: "flex",
                alignItems: "center",
                fontSize: "0.7rem",
                justifyContent: "center",
                marginRight: "20px",
              }}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress variant="determinate" value={advancement} />
              <Typography
                sx={{ fontSize: { mobile: "0.8rem", laptop: "0.8rem" } }}
                position="absolute"
              >
                {advancement}%
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{
                fontSize: { mobile: "0.8rem", laptop: "0.8rem" },
                marginRight: "10px",
                color: "#A5A5A5",
              }}
              gutterBottom
            >
              Your Activity
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 0.5,
              marginBottom: "10px",
            }}
          >
            <Chip
              sx={{
                fontSize: {
                  mobile: "0.7rem",
                  laptop: "0.7rem",
                  desktop: "0.8rem",
                },
              }}
              size="small"
              label={score + " score"}
              color="default"
            />
          </Box>
        </Box>
      </Paper>
    </React.Fragment>
  );
};
