import React, { useState, useEffect } from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { darkTheme } from "./components/customFormat";
import "@rainbow-me/rainbowkit/styles.css";
import {
  getDefaultWallets,
  lightTheme as rLightTheme,
} from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { base, baseSepolia } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { GlobalThemeContext } from "./components/home/ThemeContext";
import { Rainbow } from "./crypto/Rainbow";
import { GlobalUserContext } from "./crypto/UserContext";
import axios from "axios";
import { APIUrl } from "./components/tools/APISource";
import { GlobalExplainContext } from "./components/feed/ExplainContext";

const { chains, publicClient } = configureChains(
  [base, baseSepolia],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "My RainbowKit App",
  projectId: "YOUR_PROJECT_ID",
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

function App() {
  const [theme, setTheme] = useState<any>(darkTheme);
  const [user, setUser] = useState<any>({
    address: "0x0000000000000000000000000000000000000000",
    isConnected: false,
    isAnonymous: true,
    isWeb2: false,
    isWeb3: false,
    score: 0,
    created_at: new Date(),
  });
  const [explain, setExplain] = useState<boolean>(true);
  console.log("app theme", theme);

  // remove post
  const refreshUser = async () => {
    try {
      const response = await axios.request({
        url: `${APIUrl}/refresh`,
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      if (response.status === 200) {
        console.log("user found");
        if (response.data.user.anon) {
          const newUser = {
            address: response.data.user.address,
            isConnected: true,
            isWeb3: response.data.user.anon ? false : true,
            isWeb2: response.data.user.anon ? true : false,
            score: response.data.user.score,
            created_at: response.data.user.created_at,
            refreshed: true,
          };
          setUser(newUser);
        }
        return true;
      } else {
        console.log("user not found");
        return false;
      }
    } catch (error: any) {
      console.log("user not found");
      return false;
    }
  };

  useEffect(() => {
    console.log("refreshing user");
    refreshUser();
  }, []);

  const value = { theme, setTheme };
  const userValue = { user, setUser };
  const explainValue = { explain, setExplain };

  return (
    <GlobalThemeContext.Provider value={value}>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <GlobalUserContext.Provider value={userValue}>
          <GlobalExplainContext.Provider value={explainValue}>
            <WagmiConfig config={wagmiConfig}>
              <Rainbow />
            </WagmiConfig>
          </GlobalExplainContext.Provider>
        </GlobalUserContext.Provider>
      </ThemeProvider>
    </GlobalThemeContext.Provider>
  );
}

export default App;
