import { Avatar, Box, Button, Typography } from "@mui/material";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useGlobalThemeContext } from "../components/home/ThemeContext";
import Badge from "@mui/material/Badge";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/material/styles";
import peasant from "../data/wtf_circle.png";
import metamask from "../data/metamask.png";
import { useGlobalUserContext } from "./UserContext";
import { useEffect } from "react";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export const ConnectWeb3 = () => {
  const { theme, setTheme } = useGlobalThemeContext();
  const { user, setUser } = useGlobalUserContext();

  useEffect(() => {
    try {
      const btn = document.querySelector(
        "#connect-button"
      ) as HTMLButtonElement;
      btn.click();
    } catch (error) {
      return;
    }
  }, []);

  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");

        return (
          <Box
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {(() => {
              if (connected && !user.isConnected) {
                console.log("ok", account.address);
                const newUser = {
                  address: account.address,
                  isConnected: true,
                  isAnonymous: false,
                  isWeb2: false,
                  isWeb3: true,
                  score: 0,
                };
                setUser(newUser);
              }

              if (!connected) {
                return (
                  <Button
                    onClick={openConnectModal}
                    variant="contained"
                    startIcon={
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={metamask}
                      />
                    }
                    sx={{
                      fontSize: "0.8rem",
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.primary.light,
                      "&:hover": {
                        backgroundColor: theme.palette.background.default,
                      },
                    }}
                    id="connect-button"
                  >
                    Connect
                  </Button>
                );
              }

              if (chain.unsupported) {
                return (
                  <Button onClick={openChainModal} type="button">
                    Wrong network
                  </Button>
                );
              }

              return (
                <Box
                  style={{ display: "flex", gap: 10, justifyContent: "center" }}
                >
                  <Button
                    onClick={openChainModal}
                    type="button"
                    variant="contained"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "2px",
                      p: 1,
                      textTransform: "none",
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.background.default,
                      "&:hover": {
                        backgroundColor: theme.palette.background.default,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <img
                        alt={chain.name ?? "Chain icon"}
                        src={chain.iconUrl}
                        style={{ width: 20, height: 20 }}
                      />
                      <Typography
                        sx={{ display: { mobile: "none", tablet: "flex" } }}
                      >
                        <b>{chain.name}</b>
                      </Typography>
                    </Box>
                    <KeyboardArrowDownIcon />
                  </Button>

                  <Button
                    onClick={openAccountModal}
                    type="button"
                    variant="contained"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "2px",
                      p: 1,
                      textTransform: "none",
                      color: theme.palette.text.primary,
                      backgroundColor: theme.palette.background.default,
                      "&:hover": {
                        backgroundColor: theme.palette.background.default,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        variant="dot"
                      >
                        <Avatar
                          sx={{ background: "black", width: 20, height: 20 }}
                        >
                          <img
                            style={{ width: 20, height: 20 }}
                            src={peasant}
                          />
                        </Avatar>
                      </StyledBadge>
                      <Box sx={{ display: { mobile: "none", tablet: "flex" } }}>
                        <Typography>
                          <b>{account.displayName}</b>
                        </Typography>
                      </Box>
                      <KeyboardArrowDownIcon />
                    </Box>
                  </Button>
                </Box>
              );
            })()}
          </Box>
        );
      }}
    </ConnectButton.Custom>
  );
};
