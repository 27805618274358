import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Stack,
  Typography,
  CardContent,
} from "@mui/material";
import XIcon from "@mui/icons-material/X";
import discordIcon from "../../data/discord_blue.png";
import twitterCertified from "../../data/twitter_certified.png";
import lunch from "../../data/lunch.jpg";
import { Link } from "react-router-dom";

export const Social = () => {
  const social = [
    {
      name: "X",
      header: "A Boring Day",
      subheader: "xxx",
      username: "@0xBoringDay",
      logo: "logo/url",
      link: "https://x.com/0xBoringDay",
      content:
        "Follow us on X for real-time update on our Protocol and useful news.",
      followers: "60K+",
    },
    {
      name: "Discord",
      header: "A Boring Day",
      subheader: "xxx",
      username: "A Boring Day",
      logo: "logo/url",
      link: "https://discord.gg/UDbWQtwC",
      content:
        "Join our Discord community and share your stories with people as bored as you are.",
      followers: "100K+",
    },
  ];

  return (
    <React.Fragment>
      <Box
        sx={{
          flexDirection: { mobile: "column", laptop: "row-reverse" },
          display: "flex",
          border: "0px solid yellow",
          pointerEvent: "auto",
        }}
      >
        <Card
          variant="outlined"
          sx={{
            flexDirection: {
              mobile: "column",
              tablet: "column",
              laptop: "column",
            },
            display: "flex",
            alignItems: "center",
            gridGap: "25px",
            padding: "15px",
            boxSizing: "border-box",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                mobile: "column",
                tablet: "row",
                laptop: "column",
              },
              width: { mobile: "100%", laptop: "100%" },
            }}
          >
            <Box
              sx={{
                flexDirection: { mobile: "row", tablet: "row" },
                width: { mobile: "100%", laptop: "100%" },
                marginBottom: "10px",
              }}
            >
              <CardHeader
                subheader={
                  <Typography
                    sx={{ color: "#A5A5A5", fontFamily: "Monospace" }}
                  >
                    <b>The point of contact of Not Pieter Brueghel</b>
                  </Typography>
                }
                title={
                  <Typography
                    sx={{
                      fontFamily: "MedievalSharp",
                      fontSize: {
                        mobile: "1.2rem",
                        laptop: "1.3rem",
                        desktop: "2rem",
                      },
                    }}
                  >
                    <b>Bored together, stronger together</b>
                  </Typography>
                }
              />
              <CardContent
                sx={{
                  flexDirection: {
                    mobile: "column",
                    tablet: "column",
                    laptop: "row",
                  },
                  alignItems: "center",
                  display: "flex",
                  gridGap: "25px",
                  width: "100%",
                }}
              >
                {social.map((item: any, index: any) => {
                  return (
                    <Card
                      variant="outlined"
                      sx={{
                        padding: "15px",
                        boxSizing: "border-box",
                        maxWidth: "450px",
                      }}
                    >
                      <Stack spacing={1}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <b>{item.header}</b>
                            <img
                              alt="X"
                              src={twitterCertified}
                              style={{ marginLeft: "5px" }}
                              width="20px"
                            />
                          </Typography>
                          {item.name === "X" ? (
                            <XIcon />
                          ) : (
                            <img alt="discord" src={discordIcon} width="25px" />
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            {item.content}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: 25,
                              }}
                            >
                              {item.followers}
                            </Typography>
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: 10,
                              }}
                              color="#A5A5A5"
                            >
                              {item.name === "X" ? "Followers" : "Members"}
                            </Typography>
                          </Box>
                          <Button variant="contained">
                            <Link
                              style={{
                                color: "inherit",
                                textDecoration: "inherit",
                              }}
                              to={item.link}
                              target="_blank"
                            >
                              {item.name === "X" ? "Follow" : "Join"}
                            </Link>
                          </Button>
                        </Box>
                      </Stack>
                    </Card>
                  );
                })}
              </CardContent>
            </Box>
          </Box>
          <Box sx={{ width: { mobile: "100%", laptop: "75%" } }}>
            <img
              alt="lunch"
              src={lunch}
              width="100%"
              style={{ borderRadius: "10px" }}
            />
            <Typography
              sx={{
                fontSize: {
                  mobile: "0.7rem",
                  laptop: "0.75rem",
                  desktop: "0.8rem",
                },
                textAlign: "center",
              }}
              gutterBottom
            >
              <i>The Meeting point</i>, Not Pieter Brueghel's boring cantine
            </Typography>
          </Box>
        </Card>
      </Box>
    </React.Fragment>
  );
};
