import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { sxCommonUser } from "../customFormat";
import { useParams } from "react-router-dom";
import { FeedPost } from "../feed/FeedPost";
import { BackToFeed } from "../tools/BackToFeed";
import { Footer } from "../home/Footer";
import axios from "axios";
import { loadingPost, IPost } from "../../data/posts";
import { useGlobalUserContext } from "../../crypto/UserContext";
import { APIUrl } from "../tools/APISource";

export const Post = () => {
  let { id } = useParams();
  const [posts, setPosts] = useState<IPost[]>(loadingPost);
  const { user, setUser } = useGlobalUserContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [fetchEnd, setFetchEnd] = useState<Boolean>(false);

  const getPost = async () => {
    const response = await axios.get(`${APIUrl}/post`, {
      params: {
        post_id: id,
        user_address: user.address
          ? user.address
          : "0x0000000000000000000000000000000000000000",
      },
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
    });
    if (response.status === 200) {
      console.log("fetch data", response.data);
      return response.data;
    } else {
      console.log("no data returned");
      return null;
    }
  };

  const initialFetch = async () => {
    const post_data = await getPost();
    if (post_data) {
      setFetchEnd(false);
      setLoading(false);
      setPosts(post_data);
    } else {
      setLoading(false);
      setFetchEnd(true);
      setPosts(post_data);
    }
    return;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    initialFetch();
  }, []);

  if (!loading) {
    return (
      <React.Fragment>
        <Box
          sx={{
            display: "flex",
            flexDirection: { mobile: "row", laptop: "row", desktop: "row" },
            justifyContent: "center",
            marginLeft: { mobile: "1%", laptop: "1%", desktop: "10%" },
            marginRight: { mobile: "1%", laptop: "1%", desktop: "10%" },
            minHeight: "150vh",
          }}
        >
          <Box sx={sxCommonUser}>
            <Box
              sx={{
                display: "flex",
                width: { mobile: "100%", laptop: "600px", desktop: "650px" },
                alignItems: "left",
                flexDirection: {
                  mobile: "column",
                  laptop: "row",
                  desktop: "row",
                },
                border: "0px solid yellow",
              }}
            >
              <BackToFeed />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: { mobile: "100%", laptop: "600px", desktop: "650px" },
              }}
            >
              {posts.length === 0 ? (
                <Box>No post to show</Box>
              ) : (
                posts.map((feedPost: any, index: any) => {
                  return <FeedPost key={index} post={feedPost} />;
                })
              )}
            </Box>
          </Box>
        </Box>
        <Footer />
      </React.Fragment>
    );
  } else if (loading) {
    return <Box>LOADING</Box>;
  } else {
    return <Box>Wtf</Box>;
  }
};
