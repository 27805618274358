import * as React from 'react';
import { Box, Button } from '@mui/material';
import { Link } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useGlobalThemeContext } from '../home/ThemeContext';

export const BackToFeed = () => {

  const { theme, setTheme } = useGlobalThemeContext()

  return ( 
    <React.Fragment>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "left", textAlign: "left", border: "0px solid yellow", marginBottom: "15px" }}>
        <Link to="/feed" style={{ color: theme.palette.primary.main, textDecoration: 'none' }}>
            <Button variant="outlined" startIcon={<ArrowBackIcon/>}>
                <b>Back to Feed</b>
            </Button>
        </Link>
        </Box>
    </React.Fragment>
  )

}