import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardHeader,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import LooksThreeIcon from "@mui/icons-material/Looks3";
import LooksFourIcon from "@mui/icons-material/Looks4";
import metamask from "../../data/metamask.png";
import high_low from "../../data/highLow.png";
import post from "../../data/post.png";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import DiamondIcon from "@mui/icons-material/Diamond";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CancelIcon from "@mui/icons-material/Cancel";

export const Help = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <React.Fragment>
      <Box sx={{}}>
        <Button
          size="small"
          variant="text"
          onClick={handleOpen}
          sx={{ color: "#A5A5A5" }}
          endIcon={<HelpOutlineOutlinedIcon fontSize="small" />}
        >
          Help
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "relative",
            top: "5%",
            left: "5%",
            width: "90%",
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              zIndex: 10,
            }}
          >
            <Card
              variant="outlined"
              sx={{
                maxWidth: { laptop: "100%", desktop: "80%" },
                padding: "15px",
                boxSizing: "border-box",
              }}
              raised={true}
            >
              <CardHeader
                title={
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: "1.5rem", textAlign: "left" }}>
                      Quick Start Guide
                    </Typography>
                    <IconButton onClick={handleClose}>
                      <CancelIcon sx={{ color: "grey" }} />
                    </IconButton>
                  </Box>
                }
              />
              <Box sx={{ display: "flex", flexWrap: "wrap", gridGap: "10px" }}>
                <Card
                  variant="outlined"
                  sx={{
                    padding: "15px",
                    boxSizing: "border-box",
                    width: { laptop: "250px", desktop: "300px" },
                    backgroundColor: "#252525",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      <LooksOneIcon sx={{ marginRight: "10px" }} />
                      <b>Connect</b>
                    </Typography>
                    <Box
                      sx={{
                        display: { mobile: "none", laptop: "flex" },
                        justifyContent: "center",
                        textAlign: "center",
                        height: "130px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={metamask}
                        width="35%"
                        style={{ borderRadius: "10px" }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        display: { mobile: "none", laptop: "flex" },
                        textAlign: "left",
                      }}
                    >
                      <b>Connect anonymously</b>
                    </Typography>
                    <Typography sx={{ textAlign: "left" }}>
                      Connect in one click with more than 10 different crypto
                      wallets. No gas fee. No access required. Or connect as a
                      random Anon.
                    </Typography>
                  </Stack>
                </Card>
                <Card
                  variant="outlined"
                  sx={{
                    padding: "15px",
                    boxSizing: "border-box",
                    width: { laptop: "250px", desktop: "300px" },
                    backgroundColor: "#252525",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      <LooksTwoIcon sx={{ marginRight: "10px" }} />
                      <b>Act</b>
                    </Typography>
                    <Box
                      sx={{
                        display: { mobile: "none", laptop: "flex" },
                        justifyContent: "center",
                        textAlign: "center",
                        height: "130px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={post}
                        width="95%"
                        style={{ borderRadius: "10px" }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        display: { mobile: "none", laptop: "flex" },
                        textAlign: "left",
                      }}
                    >
                      <b>Post something new</b>
                    </Typography>
                    <Typography sx={{ textAlign: "left" }}>
                      You had a boring day? Post it and let other people know
                      how boring your day has been.
                    </Typography>
                  </Stack>
                </Card>
                <Card
                  variant="outlined"
                  sx={{
                    padding: "15px",
                    boxSizing: "border-box",
                    width: { laptop: "250px", desktop: "300px" },
                    backgroundColor: "#252525",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      <LooksThreeIcon sx={{ marginRight: "10px" }} />
                      <b>React</b>
                    </Typography>
                    <Box
                      sx={{
                        display: { mobile: "none", laptop: "flex" },
                        justifyContent: "center",
                        textAlign: "center",
                        height: "130px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={high_low}
                        width="100%"
                        style={{ borderRadius: "10px" }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        display: { mobile: "none", laptop: "flex" },
                        textAlign: "left",
                      }}
                    >
                      <b>React to other posts</b>
                    </Typography>
                    <Typography sx={{ textAlign: "left" }}>
                      If you feel other peasants had a worst day than you had,
                      let them know!
                    </Typography>
                  </Stack>
                </Card>
                <Card
                  variant="outlined"
                  sx={{
                    padding: "15px",
                    boxSizing: "border-box",
                    width: { laptop: "250px", desktop: "300px" },
                    backgroundColor: "#252525",
                  }}
                >
                  <Stack spacing={1}>
                    <Typography sx={{ display: "flex", alignItems: "center" }}>
                      <LooksFourIcon sx={{ marginRight: "10px" }} />
                      <b>Reward</b>
                    </Typography>
                    <Box
                      sx={{
                        display: { mobile: "none", laptop: "flex" },
                        justifyContent: "center",
                        textAlign: "center",
                        height: "130px",
                        alignItems: "center",
                      }}
                    >
                      <DiamondIcon fontSize="large" />
                      <CurrencyBitcoinIcon fontSize="large" />
                      <DiamondIcon fontSize="large" />
                    </Box>
                    <Typography
                      sx={{
                        display: { mobile: "none", laptop: "flex" },
                        textAlign: "left",
                      }}
                    >
                      <b>Reward or get rewarded</b>
                    </Typography>
                    <Typography sx={{ textAlign: "left" }}>
                      Grab or offer rewards to other peasants. That's the only
                      way we will make it.
                    </Typography>
                  </Stack>
                </Card>
              </Box>
            </Card>
          </Box>
        </Box>
      </Modal>
    </React.Fragment>
  );
};
