import { createContext, useContext } from "react";

export type GlobalExplain = {
  explain: boolean;
  setExplain: (c: boolean) => void;
};

export const GlobalExplainContext = createContext<GlobalExplain>({
  explain: true, // set a default value
  setExplain: () => {},
});

export const useGlobalExplainContext = () => useContext(GlobalExplainContext);
