import {
  AppBar,
  Container as muiContainer,
  Box,
  Divider,
  Drawer,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import { darkTheme, lightTheme } from "./customFormat";
import { Home } from "./home/Home";
import { Feed } from "./feed/Feed";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AgricultureIcon from "@mui/icons-material/Agriculture";
import ChatIcon from "@mui/icons-material/Chat";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ArticleIcon from "@mui/icons-material/Article";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { type ISourceOptions, type Container } from "@tsparticles/engine";
import wtfAvatar from "../data/wtf_circle.png";
import { loadSlim } from "@tsparticles/slim";
import { useEffect, useState, useMemo } from "react";
import { Wallet } from "./wallet/Wallet";
import { User } from "./user/User";
import { Post } from "./post/Post";
import { Error404 } from "./tools/Error404";
import { useGlobalThemeContext } from "./home/ThemeContext";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import { Loading } from "./tools/Loading";
import { Connector } from "../crypto/CustomConnector";
import { useGlobalUserContext } from "../crypto/UserContext";

export const MobileHeader = () => {
  const [open, setState] = useState(false);
  const { theme, setTheme } = useGlobalThemeContext();
  const { user, setUser } = useGlobalUserContext();

  //function that is being called every time the drawer should open or close, the keys tab and shift are excluded so the user can focus between the elements with the keys
  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    //changes the function state according to the value of open
    setState(open);
  };

  const [init, setInit] = useState(false);

  const particlesLoaded = (container?: Container) => {
    console.log(container);
  };

  const options: ISourceOptions = useMemo(
    () => ({
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: false,
            mode: "push",
          },
        },
        modes: {
          push: {
            quantity: 4,
          },
        },
      },
      particles: {
        color: {
          value: "#ffffff",
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "out",
          },
          random: false,
          speed: 0.5,
          straight: false,
        },
        rotate: {
          path: true,
        },
        number: {
          density: {
            enable: true,
          },
          value: 100,
        },
        opacity: {
          value: 0.5,
        },
        shape: {
          type: theme === darkTheme ? ["image", "circle"] : ["circle"],
          options: {
            image: {
              height: theme === darkTheme ? 100 : 0,
              src: wtfAvatar,
              width: theme === darkTheme ? 100 : 0,
            },
            stroke: {
              width: 0,
              color: theme === darkTheme ? "#000000" : "black",
            },
            polygon: {
              nb_sides: 5,
            },
          },
        },
        size: {
          value: { min: 1, max: 5 },
        },
      },
      detectRetina: true,
    }),
    []
  );

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  if (init) {
    return (
      <BrowserRouter>
        <Particles id="tsparticlesHeader" options={options} />

        <AppBar position="fixed">
          <Box>
            <Toolbar sx={{ display: "flex", width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexGrow: 1,
                    alignItems: "center",
                  }}
                >
                  <Link
                    to={`/`}
                    style={{ color: "inherit", textDecoration: "inherit" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gridGap: "10px",
                      }}
                    >
                      <img src={wtfAvatar} width="30px" />
                      <Typography sx={{ fontFamily: "MedievalSharp" }}>
                        A Boring Day
                      </Typography>
                    </Box>
                  </Link>
                </Box>

                <Box sx={{ marginRight: "25px" }}>
                  {/* <ConnectButton label="Connect" showBalance={false} accountStatus="avatar" chainStatus="icon" /> */}
                  <Connector />
                </Box>

                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer(true)}
                  sx={{
                    mr: "1px",
                    display: {
                      xs: "block",
                      sm: "none",
                    },
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Box>

              {/* The outside of the drawer */}
              <Drawer
                //from which side the drawer slides in
                anchor="right"
                //if open is true --> drawer is shown
                open={open}
                //function that is called when the drawer should close
                onClose={toggleDrawer(false)}
                PaperProps={{
                  sx: {
                    height: "calc(100%)",
                    backgroundColor: theme.palette.background.default,
                  },
                }}
              >
                {/* The inside of the drawer */}
                <Box
                  sx={{
                    p: 2,
                    height: 1,
                    width: "250px",
                  }}
                >
                  {/* when clicking the icon it calls the function toggleDrawer and closes the drawer by setting the variable open to false */}
                  <IconButton onClick={toggleDrawer(false)} sx={{ mb: 2 }}>
                    <CloseIcon />
                  </IconButton>

                  <Divider sx={{ mb: 2 }} />

                  <Box sx={{ mb: 2 }}>
                    <Link
                      to="/"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <AgricultureIcon sx={{ color: "primary.main" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontFamily: "MedievalSharp" }}>
                              Home
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </Link>

                    <Link
                      to="/feed"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <ChatIcon sx={{ color: "primary.main" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontFamily: "MedievalSharp" }}>
                              Feed
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </Link>

                    {user.isConnected ? (
                      <Link
                        to="/wallet"
                        style={{ color: "inherit", textDecoration: "none" }}
                      >
                        <ListItemButton>
                          <ListItemIcon>
                            <AccountBalanceWalletIcon
                              sx={{ color: "primary.main" }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography sx={{ fontFamily: "MedievalSharp" }}>
                                Wallet
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </Link>
                    ) : (
                      <></>
                    )}
                  </Box>

                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                    }}
                  >
                    <Link
                      to="https://docs.boringday.xyz/"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      <ListItemButton>
                        <ListItemIcon>
                          <ArticleIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography sx={{ fontFamily: "MedievalSharp" }}>
                              Docs
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </Link>

                    <ListItemButton
                      onClick={() =>
                        setTheme(theme === darkTheme ? lightTheme : darkTheme)
                      }
                    >
                      <ListItemIcon>
                        <Brightness4Icon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          theme === darkTheme ? (
                            <Typography sx={{ fontFamily: "MedievalSharp" }}>
                              Light Mode
                            </Typography>
                          ) : (
                            <Typography sx={{ fontFamily: "MedievalSharp" }}>
                              Dark Mode
                            </Typography>
                          )
                        }
                      />
                    </ListItemButton>
                  </Box>
                </Box>
              </Drawer>
            </Toolbar>
          </Box>
        </AppBar>

        <Box>
          <Routes>
            <Route path="/">
              <Route index element={<Home />} />
              <Route path="/feed/" element={<Feed />} />
              <Route path="/wallet/" element={<Wallet />} />
              <Route path="/user/:id" element={<User />} />
              <Route path="/post/:id" element={<Post />} />
              <Route path="*" element={<Error404 />} />
              <Route path="test" element={<Connector />} />
            </Route>
          </Routes>
        </Box>
      </BrowserRouter>
    );
  }

  return <Loading />;
};
