import * as React from "react";
import { Box, IconButton } from "@mui/material";
import XIcon from "@mui/icons-material/X";
import discord from "../../data/discord_blue.png";
import { Link } from "react-router-dom";
import { darkTheme } from "../customFormat";
import { useGlobalThemeContext } from "./ThemeContext";

export const SocialBubbles = () => {
  const { theme, setTheme } = useGlobalThemeContext();

  const social = [
    {
      name: "Twitter",
      icon: <XIcon sx={{ color: theme === darkTheme ? "white" : "black" }} />,
      url: "https://x.com/0xBoringDay",
    },
    {
      icon: <img src={discord} width="25px" />,
      url: "",
    },
    {
      icon: (
        <img
          src={"https://avatars.githubusercontent.com/u/108554348?s=200&v=4"}
          width="25px"
        />
      ),
      url: "https://basescan.org/",
    },
    {
      icon: (
        <img
          src={
            "https://support.coingecko.com/hc/article_attachments/4499575478169/CoinGecko_logo.png"
          }
          width="25px"
        />
      ),
      url: "https://www.coingecko.com/",
    },
  ];

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          flexDirection: { mobile: "row", tablet: "row" },
          gridGap: "20px",
          pointerEvent: "auto",
        }}
      >
        {/* <Typography sx={{ color: theme === darkTheme ? 'white': 'black', fontFamily: "MedievalSharp" }}>Find us on</Typography> */}
        {social.map((item: any, index: any) => {
          return (
            <Box key={index}>
              <Link to={item.url} target="_blank">
                <IconButton sx={{ height: "25px", width: "25px" }}>
                  {item.icon}
                </IconButton>
              </Link>
            </Box>
          );
        })}
      </Box>
    </React.Fragment>
  );
};
